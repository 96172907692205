import { getDirectedNeighbourElements, getNeighbourElements } from '../getNeighbourElements';
import { FrameNavigationDirection } from '../../const/pciConstants';
import { AggregatedFrameSegmentPreloader, } from './AggregatedFrameSegmentPreloader';
export class FrameSegmentBatchPreloader {
    constructor({ frameSegmentIdsSequence, asSuperAdmin = false }) {
        this.preLoadersMap = {};
        this.frameSegmentIdsSequence = [];
        this.asSuperAdmin = false;
        this.frameSegmentIdsSequence = frameSegmentIdsSequence;
        this.asSuperAdmin = asSuperAdmin;
    }
    preloadSingle({ id, imageViewType }) {
        this.preLoadersMap[id] = new AggregatedFrameSegmentPreloader();
        return this.preLoadersMap[id].load({ id, imageViewType });
    }
    /**
     * Init preloading of the element and its neighbours
     * Returns loadingPromise only for requested id
     */
    preloadWithNeighbours({ id, imageViewType }) {
        // Get neighbours
        const neighbourIds = getNeighbourElements(id, this.frameSegmentIdsSequence, 2);
        const refsMap = {};
        for (let i = 0; i < neighbourIds.length; i += 1) {
            const anId = neighbourIds[i];
            if (!this.preLoadersMap[anId]) {
                this.preLoadersMap[anId] = new AggregatedFrameSegmentPreloader();
            }
            refsMap[anId] = this.preLoadersMap[anId].load({ id: anId, imageViewType });
        }
        return refsMap;
    }
    initQueuedPreloadingAnGetFirstOne({ id, imageViewType, direction, forceReloadFrameSegment = false, }) {
        const directedNeighbourIds = getDirectedNeighbourElements({
            element: id,
            array: this.frameSegmentIdsSequence,
            direction: direction === FrameNavigationDirection.FORWARD ? 1 : -1,
            amount: 8,
        });
        return this.iterateLoadingPromisesAnGetFirstOne({
            ids: directedNeighbourIds,
            imageViewType,
            forceReloadFrameSegment,
        });
    }
    // Returns first preloading promise
    iterateLoadingPromisesAnGetFirstOne({ ids, imageViewType, forceReloadFrameSegment = false, }) {
        let cursor = -1;
        const next = async () => {
            cursor += 1;
            const anId = ids[cursor];
            const isFirstIteration = cursor === 0;
            const isLastIteration = cursor === ids.length - 1; // Test
            if (!this.preLoadersMap[anId]) {
                this.preLoadersMap[anId] = new AggregatedFrameSegmentPreloader();
            }
            const preloadingPromise = this.preLoadersMap[anId].load({
                id: anId,
                imageViewType,
                forceReloadFrameSegment: forceReloadFrameSegment && isFirstIteration,
                asSuperAdmin: this.asSuperAdmin,
            });
            if (!isLastIteration) {
                preloadingPromise.then(() => next());
            }
            return preloadingPromise;
        };
        return next();
    }
}
