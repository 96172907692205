import { jsx as _jsx } from "react/jsx-runtime";
import { DistressColorBySeverity, PCI_PALETTE, distressTypesToNotDisplay } from '../../../../const/pciConstants';
import { getSeverityIndexByPci, rgbToHex } from '../../../../utils';
import { polygonArrayToSvgPath, wktStringToSVGPath } from '../../../../utils/geometry.utilities/wktStringToFlatPolysArray';
import styles from './PciViewerDistressPolygonBB.module.scss';
export const PciViewerDistressPolygonBB = ({ distressesArray, imageViewType, activeReprKey, commonPci, }) => {
    const commonSeverityIndex = getSeverityIndexByPci(commonPci);
    const commonColor = rgbToHex([...PCI_PALETTE[commonSeverityIndex], 150]);
    const displayedDistresses = distressesArray
        .filter((distress) => !distressTypesToNotDisplay.includes(distress.type))
        .map((distress) => {
        const originalValue = distress.reprs[imageViewType][activeReprKey];
        let pathString = '';
        if (typeof originalValue === 'string') {
            pathString = wktStringToSVGPath(originalValue);
        }
        else {
            pathString = polygonArrayToSvgPath([originalValue]);
        }
        return {
            id: distress.id,
            type: distress.type,
            severity: distress.severity,
            color: DistressColorBySeverity[distress.severity],
            pathString: pathString
        };
    });
    const handleClickDistress = (distress) => {
        console.log('distress', distress);
    };
    return (_jsx("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", preserveAspectRatio: "none", className: styles.svgContainer, fill: "none", stroke: "none", strokeWidth: "0.3", children: displayedDistresses.map((displayedDistress) => (_jsx("path", { d: displayedDistress.pathString, stroke: "none", onClick: () => handleClickDistress(displayedDistress), 
            // stroke={displayedDistress.color}
            fill: commonColor }, `${displayedDistress.id}`))) }));
};
