import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import style from './PipelineListContentHead.module.scss';
import { SortableDateColumn } from '../../../components/SortableDateColumn/SortableDateColumn';
import { useWindow } from '../../../hooks/useWindow';
import { useListSortMap } from '../../../hooks/useListSortMap';
import { ContentFor } from '../../../components/ContentFor';
import { LOCAL_STORAGE_KEYS } from '../../../const/localStorageConstants';
import { Sort } from '../../../const/listingConstants';
import { ROLES } from '../../../const/userConstants';
const defaultPipelineSortMap = {
    created: Sort.DESC,
    updated: Sort.DESC,
};
export const PipelineListContentHead = ({ searchParams }) => {
    const { isDesktop } = useWindow();
    let sortMap = useListSortMap(searchParams, LOCAL_STORAGE_KEYS.PIPELINE_LIST_SORT_MAP, defaultPipelineSortMap);
    if (sortMap === undefined) {
        sortMap = {
            created: 'desc',
            success: 'desc',
            updated: 'desc',
        };
    }
    return (_jsxs("div", { className: style.listHeaderGridContainer, children: [_jsx(ContentFor, { role: ROLES.ADMIN, children: _jsx("div", { className: clsx(style.headCol, style.actionsCell), children: "Actions" }) }), _jsx("div", { className: clsx(style.headCol, style.pipelineCell), children: "Pipeline UUID" }), _jsx("div", { className: clsx(style.headCol, style.statusCell), children: "Status" }), _jsx("div", { className: clsx(style.headCol, style.emailCell), children: "Email" }), _jsx(SortableDateColumn, { url: "", className: style.headCol, lastSortOrder: sortMap.created, searchParams: searchParams, fieldName: "created", label: "Created" }), _jsx(SortableDateColumn, { url: "", lastSortOrder: sortMap.updated, searchParams: searchParams, fieldName: "updated", label: "Updated", className: "" })] }));
};
