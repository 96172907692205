import { pciFeatureProcessingStatuses } from '../../const/pciConstants';
import { asAuthorizedUser } from './normalizeUser';
export const addSuperAdminPciSearchParams = (searchParams, area = true) => {
    const field_name = area ? 'return_statuses' : 'statuses';
    searchParams.append(field_name, pciFeatureProcessingStatuses.valid);
    searchParams.append(field_name, pciFeatureProcessingStatuses.invalid);
    searchParams.append(field_name, pciFeatureProcessingStatuses.hidden);
};
export const addOperatorPciSearchParams = (searchParams, area = true) => {
    const field_name = area ? 'return_statuses' : 'statuses';
    searchParams.append(field_name, pciFeatureProcessingStatuses.valid);
    searchParams.append(field_name, pciFeatureProcessingStatuses.invalid);
};
class ApiHelpers {
    constructor() {
        this.getProfile = () => {
            throw new Error('apiHelpers: Default getProfile method');
        };
        this.setProfile = () => {
            throw new Error('apiHelpers: Default setProfile method');
        };
    }
    initProfileGetterAndSetter({ getProfile, setProfile }) {
        this.getProfile = getProfile;
        this.setProfile = setProfile;
    }
    getIsAuthorized() {
        try {
            return this.getProfile().isAuthorized;
        }
        catch (error) {
            return false;
        }
    }
    getProfileAsAuthorized() {
        return asAuthorizedUser(this.getProfile());
    }
}
export const apiHelpers = new ApiHelpers();
export const makeAreaPolygonRequestOptions = ({ options, geoAreaToRequest }) => ({
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: geoAreaToRequest ? JSON.stringify({
        geom_filter: {
            type: 'MultiPolygon',
            coordinates: geoAreaToRequest,
        },
        geom_relation: 'ST_INTERSECTS',
    }) : undefined,
});
