import { Map as StaticMap } from 'react-map-gl'
import { useState, useEffect } from 'react'
import Supercluster from 'supercluster'
import DeckGL from '@deck.gl/react'
import { CompositeLayer } from 'deck.gl'
import { GeoJsonLayer, ScatterplotLayer, TextLayer } from '@deck.gl/layers'
import { getTrackColor } from 'components/Map/mapUtils'
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
const ZOOM_THRESHOLD = 14

class TrackMapLayer extends CompositeLayer {
  state = {
    clusterIndex: null,
    zoom: 0,
  }

  constructor(props) {
    props.onClick = info => info.sourceLayer.onClick && info.sourceLayer.onClick(info)
    props.onHover = info => info.sourceLayer.onHover && info.sourceLayer.onHover(info)
    super(props)
  }

  updateState({ props, oldProps, changeFlags }) {
    if (props.tracks !== oldProps.tracks) {
      const clusterIndex = new Supercluster({
        maxZoom: ZOOM_THRESHOLD,
        radius: 10,
        minPoints: 1,
        map: properties => ({ my_count: properties.is_my ? 1 : 0 }),
        reduce: (accumulated, properties) => {
          accumulated.my_count += properties.my_count
        },
      })
      const { tracks } = this.props
      const points = tracks.map(track => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: track.track_line.geometry.coordinates[0],
        },
        properties: track.track_line.properties,
      }))
      clusterIndex.load(points)
      this.setState({ clusterIndex })
    }

    const { viewState } = props
    if (!viewState) return

    const { zoom } = viewState
    this.setState({ zoom })
  }

  renderLayers() {
    const { tracks, onTrackHover, setTrack, setViewport } = this.props
    const { zoom, clusterIndex } = this.state
    if (zoom <= ZOOM_THRESHOLD) {
      // TODO memoize or search in actual viewport
      const clusterData = clusterIndex.getClusters([-180, -85, 180, 85], zoom)
      const onClusterCLick = evt => {
        let [longitude, latitude] = evt.object.geometry.coordinates
        longitude += Math.random() * 0.001
        const zoom = ZOOM_THRESHOLD + 0.5
        const viewport = { longitude, latitude, zoom }
        setViewport(viewport)
      }
      return [
        new ScatterplotLayer({
          data: clusterData,
          getPosition: f => f.geometry.coordinates,
          getRadius: 20,
          radiusUnits: 'pixels',
          getFillColor: p => (p.properties.my_count > 0 ? [255, 200, 100, 150] : [200, 200, 200, 150]),
          pickable: true,
          onClick: onClusterCLick,
        }),
        new TextLayer({
          data: clusterData,
          getPosition: f => f.geometry.coordinates,
          getSize: 20,
          getColor: [20, 0, 20, 255],
          sizeUnits: 'pixels',
          fontFamily: 'Lato',
          getText: f => `${f.properties && f.properties.cluster ? f.properties.point_count : 1}`,
        }),
      ]
    }

    const features = {
      type: 'FeatureCollection',
      features: tracks.map(t => t.track_line),
    }

    return new GeoJsonLayer({
      data: features,
      pickable: true,
      pickingRadius: 4,
      onHover: onTrackHover,
      onClick: evt => setTrack(evt.object),
      getLineColor: p => getTrackColor(p.properties),
      getLineWidth: p => (p.properties.has_pipelines ? 4 : 2),
      lineWidthUnits: 'pixels',
    })
  }
}

const DEFAULT_STATE = {
  hoverInfo: null,
  viewState: null,
}

function TracksMap({ tracks, viewport, setTrack, setViewport }) {
  const [state, setState] = useState({ ...DEFAULT_STATE })

  const setHoverInfo = hoverInfo => setState({ hoverInfo })

  useEffect(() => {
    setState({
      ...state,
      viewState: { ...viewport },
    })
  }, [viewport])

  const { viewState, hoverInfo } = state

  const onViewStateChange = vs => {
    let { longitude, latitude, zoom } = vs.viewState
    setState({
      ...state,
      viewState: { longitude, latitude, zoom },
    })
  }

  if (viewState === null) return null

  return (
    <DeckGL
      getCursor={() => 'arrow'}
      onViewStateChange={onViewStateChange}
      controller={true}
      pickable={true}
      pickingRadius={4}
      viewState={viewState}
      layers={[
        new TrackMapLayer({
          viewState,
          pickable: true,
          tracks,
          onTrackHover: setHoverInfo,
          setTrack,
          setViewport
        })
      ]}
    >
      <StaticMap
        viewState={viewState}
        mapStyle="mapbox://styles/mapbox/dark-v9"
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      />
      {hoverInfo && hoverInfo.object && (
        <div
          className="bp4-tag bp4-large bp4-minimal bp4-round"
          style={{ position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: hoverInfo.y }}
        >
          <b>#{hoverInfo.object.properties.id}&nbsp;</b>
          {hoverInfo.object.properties.folder_name}
        </div>
      )}
    </DeckGL>
  )
}

export default TracksMap
