import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import DeckGL from '@deck.gl/react/typed';
import debounce from 'lodash/debounce'; // Todo: check if import is correct
import { getCursor } from '../../../utils';
import { RoadLyMapContext } from '../../../contexts/roadLyMapContext';
import { RoadLyMapPopup } from '../RoadLyMapPopup';
import styles from './DeckGLMap.module.css';
import { ClusterFeaturePopup } from '../ClusterFeaturePopup';
import { getViewportBoundsByViewState } from '../../../utils/viewportUtilities/getViewportBoundsByViewState';
function typedDebounce(func) {
    return debounce(func, 400);
}
const debouncedHandler = typedDebounce(({ params: { viewState, boundingBox }, onNavigationEnd, }) => {
    if (onNavigationEnd) {
        onNavigationEnd(viewState, boundingBox);
    }
});
export const DeckGLMap = ({ isSmall, onClickDeckGL, onMouseMoveOverMap, initialViewState, children, onNavigationEnd, touchRotate, dragEventsConfig, screenShotProps, getTooltip, roadLyMapPopupProps, onClickRoadLyMapClusterSubFeature, }) => {
    const handleViewStateChangeEnd = ({ viewState, boundingBox }) => {
        // @ts-ignore // todo
        debouncedHandler({ params: { viewState, boundingBox }, onNavigationEnd });
    };
    const [roadLyMapContext, setRoadLyMapContext] = useContext(RoadLyMapContext);
    const { onAfterRender, deckRef } = screenShotProps || {};
    const handleViewStateChange = (viewStateChangeParamsInput) => {
        // Todo: do through type guard instead of as:
        const viewStateChangeParams = viewStateChangeParamsInput;
        // Todo: make bounding box polygon out of new params
        viewStateChangeParams.boundingBox = getViewportBoundsByViewState(viewStateChangeParams.viewState);
        setRoadLyMapContext({
            ...roadLyMapContext,
            viewState: viewStateChangeParams.viewState,
            boundingBox: viewStateChangeParams.boundingBox,
        });
        // Trigger debounced external handler
        handleViewStateChangeEnd(viewStateChangeParams);
    };
    return (_jsxs("div", { className: isSmall ? styles.smallMap : styles.map, children: [roadLyMapPopupProps ? _jsx(RoadLyMapPopup, { ...roadLyMapPopupProps }) : null, roadLyMapContext.activeCluster
                ? (_jsx(ClusterFeaturePopup, { onClickRoadLyMapClusterSubFeature: onClickRoadLyMapClusterSubFeature, activeCluster: roadLyMapContext.activeCluster }))
                : null, _jsx(DeckGL, { initialViewState: initialViewState, controller: { touchRotate, dragPan: dragEventsConfig === null || dragEventsConfig === void 0 ? void 0 : dragEventsConfig.dragPan }, onDragStart: dragEventsConfig === null || dragEventsConfig === void 0 ? void 0 : dragEventsConfig.onDragStart, ...(dragEventsConfig || {}), pickingRadius: 4, onViewStateChange: handleViewStateChange, onClick: onClickDeckGL, onHover: onMouseMoveOverMap, getCursor: getCursor, onAfterRender: onAfterRender, ref: deckRef, glOptions: { preserveDrawingBuffer: true }, getTooltip: getTooltip, children: children })] }));
};
