import { ButtonGroup, Button } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import { PROGRESS } from 'const'
import { getQueryString } from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/utils'

import { SessionsListItem } from '../SessionsListItem'

import style from './SessionsListContent.module.css'

import DeckGLContextProvider from '../../../contexts/deckGlContextProvider'

function SessionsListEmpty() {
  return <div className={style.empty}>We don't have any sessions.</div>
}

function SessionsListError() {
  return <div className={style.empty}>Something went wrong. Please try again later.</div>
}

function PaginationHref({ label, disabled, skip, searchParams }) {
  const query = getQueryString(searchParams, { skip })
  if (disabled) {
    return <Button disabled={disabled}>{label}</Button>
  }
  return (
    <Link role="button" tabIndex={0} to={{ search: query }} className={'bp4-button'}>
      {label}
    </Link>
  )
}

function SessionsListPagination({ limit, skip, total, searchParams }) {
  const _limit = parseInt(limit, 10)
  const _skip = parseInt(skip, 10)
  const _total = parseInt(total, 10)

  const hasNextPage = _skip + _limit < _total
  const hasPreviousPage = _skip > 0

  return (
    <div className={style.pagination}>
      <ButtonGroup>
        <PaginationHref
          label={'Prev page'}
          disabled={!hasPreviousPage}
          skip={hasPreviousPage ? _skip - _limit : 0}
          searchParams={searchParams}
        />
        <PaginationHref
          label={'Next page'}
          disabled={!hasNextPage}
          skip={hasNextPage ? _skip + _limit : _skip}
          searchParams={searchParams}
        />
      </ButtonGroup>
    </div>
  )
}

export function SessionsListContent({ progress, items, limit, skip, total, searchParams }) {
  if (progress === PROGRESS.WORK) {
    return <ProgressBar />
  }

  if (items.length === 0 && progress === PROGRESS.SUCCESS) {
    return <SessionsListEmpty />
  }

  if (items.length === 0 && progress === PROGRESS.ERROR) {
    return <SessionsListError />
  }

  return (
    <>
      <DeckGLContextProvider>
        {items.map(item => (
          <SessionsListItem key={item.uuid} item={item} />
        ))}
      </DeckGLContextProvider>
      <SessionsListPagination limit={limit} skip={skip} total={total} searchParams={searchParams} />
    </>
  )
}
