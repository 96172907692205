import sortBy from 'lodash/sortBy';
import { apiAsClass } from '../../../services/ApiService';
import { fcFromFeatures } from '../../../utils/geoJson.utils';
import { ROLES } from '../../../const/userConstants';
import { filterPciMapDataForDemoUser } from '../../../utils/filterPciMapDataForDemoUser/filterPciMapDataForDemoUser';
export const fetchAndApplyPciDataSideResults = {
    outOfZoom: 'outOfZoom',
};
export function isSideResult(input) {
    return Boolean(fetchAndApplyPciDataSideResults[input]);
}
export const fetchAndApplyPciData = async ({ zoom, pipelineId, // string or null
profile, appendSampleUnitsData, appendViewpointsData, geoAreaToRequest, }) => {
    if (!zoom) {
        throw new Error('Zoom is not passed');
    }
    const asSuperAdmin = profile.roles.includes(ROLES.SUPERADMIN);
    // const nonHardFilteredMixedFeatureCollection = await apiAsClass.getPciRegistryArea({ pipelineId, boundingBox });
    // eslint-disable-next-line no-nested-ternary
    const nonHardFilteredMixedFeatureCollection = profile.isStaticUser
        ? await apiAsClass.getStaticPciRegistryArea(profile.organization_id)
        : geoAreaToRequest
            ? await apiAsClass.getPciRegistryArea({ pipelineId, geoAreaToRequest, asSuperAdmin })
            : await apiAsClass.getWholePciRegistry({ pipelineId, asSuperAdmin });
    // Hard filter features by user name
    const mixedFeatureCollection = profile.isDemoUser
        ? filterPciMapDataForDemoUser(nonHardFilteredMixedFeatureCollection)
        : nonHardFilteredMixedFeatureCollection;
    const viewpointFeatures = [];
    const sampleUnitFeatures = [];
    mixedFeatureCollection.features.forEach((feature) => {
        switch (feature.properties.type) {
            case 'FrameSegment':
                viewpointFeatures.push(feature);
                break;
            case 'section':
            case 'sample_unit':
                sampleUnitFeatures.push(feature);
                break;
            default:
                console.error(`Unknown feature type: ${feature.properties.type}`);
        }
    });
    const sortedViewpointFeatures = sortBy(viewpointFeatures, (el) => el.properties.id);
    const sampleUnitsFeatureCollection = fcFromFeatures(sampleUnitFeatures);
    const viewpointsFeatureCollection = fcFromFeatures(sortedViewpointFeatures);
    // Update PCI data set
    appendSampleUnitsData(sampleUnitsFeatureCollection);
    appendViewpointsData(viewpointsFeatureCollection);
    return {
        viewpointsFeatureCollection,
        sampleUnitsFeatureCollection,
    };
};
