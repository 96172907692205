import { useEffect, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { useSearchParams } from 'react-router-dom';
import { API } from '../services/ApiService';
import { Progress } from '../const/appConstants';
import { PipelineListFilters } from '../const/pipelineConstants';
import { parse, parseNumber, parseString, parseFilter, parseSort, parseBoolean, } from '../utils/pipelineListParsers/pipelineListParsers';
const { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_SORT_ORDER, DEFAULT_SORT_FIELD, DEFAULT_STATUS, DEFAULT_OWNER, DEFAULT_SESSION, DEFAULT_IS_COMPLETE_VIEW, DEFAULT_ORGANIZATION, } = PipelineListFilters;
const updatePipelineName = async (uuid, payload) => {
    await API.updatePipelineName(uuid, payload);
};
export const usePipelineList = () => {
    const [searchParams] = useSearchParams();
    const limit = parse(searchParams, 'limit', DEFAULT_LIMIT, parseNumber);
    const skip = parse(searchParams, 'skip', DEFAULT_SKIP, parseNumber);
    const sort_field = parse(searchParams, 'sort_field', DEFAULT_SORT_FIELD, parseString);
    const sort_order = parse(searchParams, 'sort_order', DEFAULT_SORT_ORDER, parseSort);
    const status = parse(searchParams, 'status', DEFAULT_STATUS, parseFilter);
    const owner = parse(searchParams, 'owner', DEFAULT_OWNER, parseString);
    const session = parse(searchParams, 'session', DEFAULT_SESSION, parseString);
    const organization = searchParams.get('organization') || DEFAULT_ORGANIZATION;
    const modules = searchParams.getAll('modules');
    const modules_str = modules.sort().join(',');
    const types = searchParams.getAll('types');
    const types_str = types.sort().join(',');
    const is_complete_view = parse(searchParams, 'is_complete_view', DEFAULT_IS_COMPLETE_VIEW, parseBoolean);
    const pipeline_name = searchParams.get('name');
    const uuid = searchParams.get('uuid');
    const [state, updateState] = useImmer({
        items: [],
        total: 0,
        progress: Progress.IDLE,
    });
    const updateItemPart = (uuid, name, showToastSuccessUpdate, showToastFailureUpdate) => {
        updateState((draft) => {
            const item = draft.items.find((draft_item) => draft_item.uuid === uuid);
            if (!item)
                return;
            item.name = name;
        });
        updatePipelineName(uuid, {
            name,
        })
            .then(showToastSuccessUpdate)
            .catch(showToastFailureUpdate);
    };
    const onRequestDataFailed = useCallback(() => {
        updateState((draft) => {
            draft.progress = Progress.ERROR;
        });
    }, [updateState]);
    const fetchAndUpdatePipelineListState = useCallback((params) => {
        updateState((draft) => {
            draft.progress = Progress.WORK;
            draft.items = [];
        });
        API.getPipelineListShort(params)
            .then((req) => {
            updateState((draft) => {
                draft.progress = Progress.SUCCESS;
                draft.items = req.items;
                draft.total = req.meta.total;
            });
        })
            .catch(onRequestDataFailed);
    }, [updateState, onRequestDataFailed]);
    const updatePipelineList = useCallback(() => {
        const modules_or_null = modules.length > 0 ? modules : null;
        const types_or_null = types.length > 0 ? types : null;
        fetchAndUpdatePipelineListState({
            limit,
            skip,
            sort_field,
            sort_order,
            status,
            owner,
            organization,
            is_complete_view,
            name: pipeline_name,
            uuid,
            modules: modules_or_null,
            types: types_or_null,
            hdmp_session_id: session,
        });
    }, [fetchAndUpdatePipelineListState, modules]);
    useEffect(() => {
        updatePipelineList();
    }, [
        limit,
        skip,
        sort_field,
        sort_order,
        status,
        owner,
        session,
        modules_str,
        is_complete_view,
        types_str,
        pipeline_name,
        uuid,
        organization,
    ]);
    return {
        progress: state.progress,
        items: state.items,
        total: state.total,
        limit,
        skip,
        sort_field,
        sort_order,
        status,
        owner,
        session,
        modules,
        is_complete_view,
        types,
        pipeline_name,
        uuid,
        organization,
        updatePipelineList,
        updateItemPart,
    };
};
