import { LoadingStatus } from '../../const/appConstants';
import { AbstractPreloader } from '../AbstractPreloader';
import { API } from '../../services/ApiService';
export class FrameSegmentPreloader extends AbstractPreloader {
    constructor() {
        super();
        this.state = { loadingStatus: LoadingStatus.INITIAL, data: {} };
    }
    getResult(stateData) {
        return stateData;
    }
    async load({ id, asSuperAdmin = false }) {
        return await API.getMiniPciFrameSegment(id, asSuperAdmin);
    }
}
