import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { clsx } from 'clsx';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Position } from '@blueprintjs/core/lib/esm/common';
import { useNavigate } from 'react-router';
import { AssetsIcon, AssetsRegistryIcon, PciMapIcon, PipelinesIcon, SessionIcon, SessionOnMapIcon } from '../../components/icons';
import { PATH } from '../../const/routingConstants';
import { PreferredSignLogo } from '../../components/Logo';
import styles from './AppSidebar.module.scss';
import { UserDropdown } from './UserDropdown';
import { CacheControlContainer } from './CacheControl/CacheControl.container';
import { userIsSuperAdmin } from '../../hooks/useRoles';
export const AppSidebar = ({ className, buttonVisibilityMap }) => {
    const { pciRegistry, assetsPipelines, assetsRegistry } = buttonVisibilityMap;
    const isSuperAdmin = userIsSuperAdmin();
    const navigate = useNavigate();
    const handleGoToMiniPci = () => {
        navigate(PATH.pciRegistry.path);
    };
    const handleGoToAssets = () => {
        navigate(PATH.assetsPipelineViewer.path);
    };
    const handleGoToAssetsRegistry = () => {
        navigate(PATH.aggregatedAssetsViewer.path);
    };
    const handleGoToPipelines = () => {
        navigate(PATH.pipelinesList.path);
    };
    const handleGoToSessions = () => {
        navigate(PATH.sessionsList.path);
    };
    const handleGoToSessionsOnMap = () => {
        navigate(PATH.sessionsMap.path);
    };
    const handleMiddleClick = (path) => {
        window.open(path, '_blank'); // Open in new tab
    };
    return (_jsxs("div", { className: clsx(styles.appSidebar, className), children: [_jsx("div", { className: styles.logo, children: _jsx("a", { href: "/", children: _jsx(PreferredSignLogo, {}) }) }), _jsxs("div", { className: styles.appSidebarActionsContainer, children: [_jsxs("div", { className: styles.appSidebarGroup, children: [pciRegistry ? (_jsx(_Fragment, { children: _jsx("div", { className: styles.appSidebarGroupSection, onClick: handleGoToMiniPci, onAuxClick: event => {
                                        if (event.button === 1)
                                            handleMiddleClick(PATH.pciRegistry.path);
                                    }, children: _jsx(Tooltip2, { className: styles.tooltipTarget, position: Position.RIGHT, content: "PCI Inspection", children: _jsx(PciMapIcon, {}) }) }) })) : null, assetsPipelines ? (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.separator }), _jsx("div", { className: styles.appSidebarGroupSection, onClick: handleGoToAssets, onAuxClick: event => {
                                            if (event.button === 1)
                                                handleMiddleClick(PATH.assetsPipelineViewer.path);
                                        }, children: _jsx(Tooltip2, { className: styles.tooltipTarget, position: Position.RIGHT, content: "Assets Inspection", children: _jsx(AssetsIcon, {}) }) })] })) : null, assetsRegistry ? (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.separator }), _jsx("div", { className: styles.appSidebarGroupSection, onClick: handleGoToAssetsRegistry, onAuxClick: event => {
                                            if (event.button === 1)
                                                handleMiddleClick(PATH.aggregatedAssetsViewer.path);
                                        }, children: _jsx(Tooltip2, { className: styles.tooltipTarget, position: Position.RIGHT, content: "Assets Registry", children: _jsx(AssetsRegistryIcon, {}) }) })] })) : null] }), isSuperAdmin && (_jsxs("div", { className: styles.appSidebarGroup, children: [_jsx("div", { className: styles.appSidebarGroupSection, onClick: handleGoToPipelines, onAuxClick: event => {
                                    if (event.button === 1)
                                        handleMiddleClick(PATH.pipelinesList.path);
                                }, children: _jsx(Tooltip2, { className: styles.tooltipTarget, position: Position.RIGHT, content: "Pipelines", children: _jsx(PipelinesIcon, {}) }) }), _jsx("div", { className: styles.separator }), _jsx("div", { className: styles.appSidebarGroupSection, onClick: handleGoToSessions, onAuxClick: event => {
                                    if (event.button === 1)
                                        handleMiddleClick(PATH.sessionsList.path);
                                }, children: _jsx(Tooltip2, { className: styles.tooltipTarget, position: Position.RIGHT, content: "Sessions", children: _jsx(SessionIcon, {}) }) }), _jsx("div", { className: styles.separator }), _jsx("div", { className: styles.appSidebarGroupSection, onClick: handleGoToSessionsOnMap, onAuxClick: event => {
                                    if (event.button === 1)
                                        handleMiddleClick(PATH.sessionsMap.path);
                                }, children: _jsx(Tooltip2, { className: styles.tooltipTarget, position: Position.RIGHT, content: "Sessions on map", children: _jsx(SessionOnMapIcon, {}) }) }), _jsx("div", { className: styles.separator }), _jsx("div", { className: styles.appSidebarGroupSection, children: _jsx(Tooltip2, { className: styles.tooltipTarget, position: Position.RIGHT, content: "Cache control", children: _jsx(CacheControlContainer, {}) }) })] })), _jsx(UserDropdown, {})] })] }));
};
