import React, { useState } from 'react';
import { usePipelineList } from '../../hooks/usePipelineList'
import { PipelineList } from '@roadar-pipeline-viewer/roadly-typescript/dist/features/PipelineList/PipelineList'

/**
 * @deprecated
 * This is an old implementation, kept only in case someone will need it
 * New version is PipelineListPageTs
 */
export function PipelineListPage() {
  const {
    progress,
    items,
    limit,
    skip,
    sort_field,
    sort_order,
    owner,
    session,
    status,
    total,
    modules,
    types,
    is_complete_view,
    pipeline_name,
    uuid,
    organization,
    updatePipelineList,
    updateItemPart,
  } = usePipelineList();
  const [selectedSession, setSelectedSession] = useState();
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const searchParams = {
    limit,
    skip,
    sort_field,
    sort_order,
    status,
    owner,
    session,
    modules,
    types,
    organization,
    name: pipeline_name,
    uuid,
    is_complete_view,
  };
  return (
    <PipelineList
      progress={progress}
      items={items}
      limit={limit}
      skip={skip}
      sort_field={sort_field}
      sort_order={sort_order}
      status={status}
      total={total}
      owner={owner}
      session={session}
      modules={modules}
      types={types}
      organization={organization}
      updatePipelineList={updatePipelineList}
      updateItemPart={updateItemPart}
      is_complete_view={is_complete_view}
      pipeline_name={pipeline_name}
      uuid={uuid}
      selectedOrganization={selectedOrganization}
      setSelectedOrganization={setSelectedOrganization}
      selectedSession={selectedSession}
      setSelectedSession={setSelectedSession}
      selectedModules={selectedModules}
      setSelectedModules={setSelectedModules}
      searchParams={searchParams}
    />
  );
}
