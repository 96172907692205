import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Tab, Tabs } from '@blueprintjs/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindow } from '../../../hooks/useWindow';
import { getQueryString } from '../../../utils/utils';
import { PATH } from '../../../const/routingConstants';
import styles from './PipelineListTabs.module.scss';
import { TabsWrap } from '../../../components/TabsWrap/TabsWrap';
export const DEFAULT_SESSION = 'all';
export const PipelineListTabs = ({ searchParams, selectedSession }) => {
    const { isDesktop } = useWindow();
    const navigate = useNavigate();
    const location = useLocation();
    const [tab, setTab] = useState('all');
    const handleTabChange = (e) => {
        setTab(e);
    };
    useEffect(() => {
        const query = getQueryString(searchParams, { status: tab });
        navigate({
            ...location,
            search: `?${query}`,
        });
    }, [tab]);
    return (_jsx("div", { children: _jsx(TabsWrap, { tabs: (_jsxs(Tabs, { id: "statusTabs", selectedTabId: tab, onChange: handleTabChange, children: [_jsx(Tab, { id: "all", title: "All", className: styles.tab }), _jsx(Tab, { id: "active", title: "Active", className: styles.tab }), _jsx(Tab, { id: "canceled", title: "Canceled", className: styles.tab }), _jsx(Tab, { id: "failed", title: "Failed", className: styles.tab }), _jsx(Tab, { id: "success", title: "Success", className: styles.tab })] })), handleAddNewPipelineClick: () => {
                navigate({
                    pathname: PATH.runPipeline.path,
                    search: selectedSession ? `?session=${selectedSession.id}` : '',
                });
            } }) }));
};
