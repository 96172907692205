import { LoadingStatus } from '../../const/appConstants';
export class AbstractPreloader {
    async get(params, forceReload = false) {
        if (forceReload) {
            return this.startLoading(params);
        }
        if (this.state.loadingStatus === LoadingStatus.SUCCESS) {
            return this.getResult(this.state.data);
        }
        if (this.state.loadingStatus === LoadingStatus.LOADING) {
            const completeStateData = await this.state.onSuccessPromise;
            return this.getResult(completeStateData);
        }
        /*
         * First time flow
         */
        return this.startLoading(params);
    }
    async startLoading(params) {
        const onSuccessPromise = this.load(params);
        /*
         * Go to loading state
         */
        this.state = {
            loadingStatus: LoadingStatus.LOADING,
            onSuccessPromise,
            data: {},
        };
        try {
            const completeStateData = await this.state.onSuccessPromise;
            if (completeStateData === null) {
                throw new Error('Frame segment not found');
            }
            this.state = {
                loadingStatus: LoadingStatus.SUCCESS,
                data: completeStateData,
            };
            return this.getResult(this.state.data);
        }
        catch (error) {
            this.state = {
                loadingStatus: LoadingStatus.ERROR,
                data: {},
            };
            throw error;
        }
    }
}
