import { toast, ToastContainer } from 'react-toastify'
import clsx from 'clsx'
import { Elevation, Card, Intent, Tag, Icon, ProgressBar } from '@blueprintjs/core'
import { ROLES, INVITE_STATUS, DEVELOPMENT, REACT_APP_TARGET, REACT_APP_URL } from 'const'
import { ContentFor } from 'components/ContentFor/ContentFor'
import utils from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/utils';
import style from './InvitesList.module.css'

function getStatusIntent(status) {
  switch (status) {
    case INVITE_STATUS.ACTIVATED:
      return Intent.SUCCESS
    case INVITE_STATUS.EXPIRED:
      return Intent.DANGER
    default:
      return Intent.NONE
  }
}

const toastProps = {
  position: 'bottom-center',
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: 'dark',
}

const getInviteLink = link => {
  if (DEVELOPMENT && link.includes(REACT_APP_TARGET) && REACT_APP_URL) {
    return link.replace(REACT_APP_TARGET, REACT_APP_URL)
  }

  return link
}

function InviteRow({ invite }) {
  const onCopyClick = () => {
    navigator.clipboard.writeText(getInviteLink(invite.link))
    toast('Invite link is copied to clipboard', toastProps)
  }

  return (
    <div className={style.invitesList}>
      <div className={clsx(style.row, 'bp4-text-small')}>{utils.formatDate(invite.create_ts)}</div>
      <div className={clsx(style.row, 'bp4-text-small')}>{utils.formatDate(invite.expires_ts)}</div>
      <div className={clsx(style.row, 'bp4-text-small')}>{invite.email}</div>
      <div className={clsx(style.row, 'bp4-text-small')}>{invite.description}</div>
      <div className={clsx(style.row, 'bp4-text-small')}>
        {invite.status === INVITE_STATUS.CREATED && (
          <Tag intent={Intent.PRIMARY} className={style.copy} onClick={onCopyClick}>
            <Icon icon="duplicate" size={12} className="icon" />
            Copy
          </Tag>
        )}
        {invite.status !== INVITE_STATUS.CREATED && '-'}
      </div>
      <div className={clsx(style.row, 'bp4-text-small')}>
        <Tag intent={getStatusIntent(invite.status)}>{invite.status.toUpperCase()}</Tag>
      </div>
    </div>
  )
}

function InvitesList({ invites, isLoading }) {
  if (isLoading) {
    return <ProgressBar value={1} />
  }

  if (!isLoading && invites.length === 0) {
    return null
  }

  return (
    <ContentFor role={ROLES.ADMIN}>
      <div className={style.wrap}>
        <Card elevation={Elevation.ONE} className={style.invitesList}>
          <div className={clsx(style.row, 'bp4-text-small')}>Created at</div>
          <div className={clsx(style.row, 'bp4-text-small')}>Expired at</div>
          <div className={clsx(style.row, 'bp4-text-small')}>Email</div>
          <div className={clsx(style.row, 'bp4-text-small')}>Comment</div>
          <div className={clsx(style.row, 'bp4-text-small')}>Link</div>
          <div className={clsx(style.row, 'bp4-text-small')}>Status</div>
        </Card>
        {!isLoading && invites.map(invite => <InviteRow key={invite.link} invite={invite} />)}
        <ToastContainer />
      </div>
    </ContentFor>
  )
}

export default InvitesList
