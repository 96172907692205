import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { LoadingStatus } from '../../../const/appConstants';
import { RoadLyMapContext } from '../../../contexts/roadLyMapContext';
import { LoadingOverlay } from '../../../components';
import { apiAsClass } from '../../../services/ApiService';
import { asAuthorizedUser } from '../../../services/ApiService/normalizeUser';
import { PciTableView } from './PciTableView';
import { useProfile } from '../../../hooks/useProfile';
export const PciTableViewContainer = () => {
    const [roadLyMapContext] = useContext(RoadLyMapContext);
    const { profile } = useProfile();
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.LOADING);
    const [data, setData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    useEffect(() => {
        const effect = async () => {
            setLoadingStatus(LoadingStatus.LOADING);
            try {
                const newData = await apiAsClass.viewportExportPciDataForTable([[roadLyMapContext.boundingBox]], asAuthorizedUser(profile).organization_id);
                setData(newData);
                setLoadingStatus(LoadingStatus.SUCCESS);
            }
            catch (error) {
                console.error('Failed to fetch PCI data:', error);
                if (error instanceof Error) {
                    setErrorMessage(error.message);
                }
                else {
                    setErrorMessage('An unknown error occurred');
                }
                setLoadingStatus(LoadingStatus.ERROR);
            }
        };
        effect();
    }, []);
    return (_jsxs(_Fragment, { children: [loadingStatus === LoadingStatus.LOADING ? _jsx(LoadingOverlay, {}) : null, loadingStatus === LoadingStatus.SUCCESS ? _jsx(PciTableView, { data: data }) : null, loadingStatus === LoadingStatus.ERROR ? (_jsx("div", { style: { textAlign: 'center' }, children: errorMessage })) : null] }));
};
