import { difference, union } from 'polygon-clipping';
import { useRef } from 'react';
export function useLoadedViewports() {
    const loadedPolys = useRef([]);
    const updateAndGetPolygon = (poly) => {
        const differenceResult = difference(poly, loadedPolys.current);
        loadedPolys.current = union(poly, loadedPolys.current);
        return differenceResult;
    };
    const removePolyFromLoadedPolys = (poly) => {
        loadedPolys.current = difference(loadedPolys.current, poly);
    };
    return {
        loadedPolys,
        updateAndGetPolygon,
        removePolyFromLoadedPolys,
    };
}
