import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import styles from './TabsWrap.module.scss';
import { PATH } from '../../const/routingConstants';
import { PlusIcon, PciMapIcon } from '../icons';
export const TabsWrap = ({ tabs, handleAddNewPipelineClick }) => {
    const navigate = useNavigate();
    return (_jsxs("div", { className: styles.tabsWrap, children: [_jsxs("div", { className: styles.buttonsWrap, children: [_jsxs(Button, { className: clsx('bp4-minimal', styles.button), onClick: () => navigate({
                            pathname: PATH.sessionsMap.path,
                        }), children: [_jsx(PciMapIcon, { color: "#F2994A", size: 20, isMarginNeeded: true }), "Sessions on map"] }), _jsxs(Button, { className: clsx('bp4-minimal', styles.button), onClick: handleAddNewPipelineClick, children: [_jsx(PlusIcon, { color: "#F2994A", isMarginNeeded: true }), "Add new pipeline"] })] }), tabs ? (_jsx("div", { className: styles.tabs, children: tabs })) : null] }));
};
