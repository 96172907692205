import { OWNER, SESSIONS_LIST_FILTERS } from 'const'
import { useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import API from 'utils/api'
import { getQueryString } from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/utils';
import { FilterByUsers } from '.'

const commonPartEmailsList = [
  { email: OWNER.ALL, description: 'Select all users' },
  { email: OWNER.ME, description: 'Select only you' },
]

export const FilterByUsersContainer = ({ owner, searchParams }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [items, setItems] = useState(commonPartEmailsList)
  const [isLoading, setIsLoading] = useState(true)
  const [value, setValue] = useState()

  useEffect(() => {
    setIsLoading(true)
    API.getEmails()
      .then(all_emails => {
        let emailSelected = undefined
        if (!Array.isArray(all_emails)) {
          emailSelected = [...commonPartEmailsList].find(user => user.email === owner)
        } else {
          setItems([...commonPartEmailsList, ...all_emails])
          emailSelected = [...commonPartEmailsList, ...all_emails].find(user => user.email === owner)
        }
        setValue(emailSelected)
        setIsLoading(false)
      })
      .catch(() => {
        const emailSelected = [...commonPartEmailsList].find(user => user.email === owner)
        setValue(emailSelected)
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onItemPredicate = (query, item) => {
    return item.email?.toLowerCase().includes(query.toLowerCase()) || item.description?.toLowerCase().includes(query)
  }

  const onItemSelect = emailInput => {
    setValue(emailInput)
    const searchParamsWithSkip = { ...searchParams, skip: SESSIONS_LIST_FILTERS.DEFAULT_SKIP }
    const query = getQueryString(searchParamsWithSkip, { owner: emailInput.email })
    navigate({
      ...location,
      search: `?${query}`,
    })
  }

  return (
    <FilterByUsers
      items={items}
      isLoading={isLoading}
      value={value}
      onItemSelect={onItemSelect}
      onItemPredicate={onItemPredicate}
    />
  )
}
