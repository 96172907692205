import { emailValidationPattern } from 'const'
import { useState } from 'react'
import { Tooltip2 } from '@blueprintjs/popover2'
import { FormGroup, Tag, Intent, Button, Classes, Dialog, InputGroup } from '@blueprintjs/core'
import { useForm } from 'react-hook-form'
import API from 'utils/api'
import style from './CreateInviteForm.module.css'
import clsx from 'clsx'
import { parseErrorsDetialResponse } from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/utils';

const formOptions = {
  email: {
    pattern: emailValidationPattern,
  },
  description: {
    required: 'Description is required',
    minLength: { value: 1, message: 'Description is too short' },
  },
}

function SuccessDialog({ isOpen, onClose, invite = {} }) {
  const lockButton = (
    <Tooltip2 content="copy">
      <Button
        icon={'duplicate'}
        intent={Intent.SUCCESS}
        minimal={true}
        onClick={() => navigator.clipboard.writeText(invite.link)}
      />
    </Tooltip2>
  )

  return (
    <Dialog
      className={Classes.OVERLAY_SCROLL_CONTAINER}
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      canOutsideClickClose
    >
      <div>
        <div className={Classes.DIALOG_HEADER}>
          <h5 className="bp4-heading">Invite successfully created!</h5>
          <Button icon="cross" className={Classes.DIALOG_CLOSE_BUTTON} onClick={onClose} />
        </div>
        <div className={Classes.DIALOG_BODY}>
          <p>You can copy link and send it to user.</p>
          <p>Invite link:</p>
          <div className="bp4-input-group">
            <InputGroup id="link" value={invite.link} rightElement={lockButton} type="text" />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

function CreateInviteForm({ updateInvites }) {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm()
  const [requestErrors, setRequestErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [inviteResult, setInviteResult] = useState()

  const onSubmit = data => {
    setRequestErrors([])
    setIsLoading(true)
    API.createInvite(data).then(({ status, data }) => {
      setIsLoading(false)
      if (status === 200) {
        updateInvites()
        reset()
        setInviteResult(data)
      } else {
        parseErrorsDetialResponse(data, Object.keys(formOptions), setError, setRequestErrors)
      }
    })
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup label="Email:" labelFor="email">
          <div className="bp4-input-group">
            <input
              id="email"
              className="bp4-input"
              type="text"
              placeholder="some@email.com"
              {...register('email', formOptions['email'])}
            />
          </div>
          {errors.email && (
            <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
              {errors.email.message}
            </Tag>
          )}
        </FormGroup>
        <FormGroup label="Description:" labelFor="description">
          <div className="bp4-form-content">
            <textarea
              id="description"
              placeholder="Write answers for two question: Who? For what?"
              name="description"
              className={clsx(style.textarea, 'bp4-input bp4-small')}
              {...register('description', formOptions['description'])}
            />
          </div>
          {errors.description && (
            <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
              {errors.description.message}
            </Tag>
          )}
        </FormGroup>
        <Button loading={isLoading} type="submit" intent={Intent.PRIMARY} text="Create" />
        {requestErrors.map(({ loc, msg }) => (
          <Tag key={msg} multiline fill large intent={Intent.DANGER} className={style.error}>
            {`${loc}: ${msg}`}
          </Tag>
        ))}
      </form>
      <SuccessDialog isOpen={Boolean(inviteResult)} onClose={() => setInviteResult()} invite={inviteResult} />
    </div>
  )
}

export default CreateInviteForm
