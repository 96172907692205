import { jsx as _jsx } from "react/jsx-runtime";
import { PCI_PALETTE, severityRgbColors, pciSeverityNames, pciSeverityNamesSortedArray } from '../../../const/pciConstants';
import { capitalize, getSeverityIndexByPci, rgbToHex } from '../../../utils';
import styles from './PciSeverityMark.module.scss';
export const PciSeverityMark = ({ pci }) => {
    const borderColorIndex = getSeverityIndexByPci(pci);
    const backgroundColor = rgbToHex(PCI_PALETTE[borderColorIndex]);
    const severityName = capitalize(pciSeverityNamesSortedArray[borderColorIndex]);
    return (_jsx("div", { className: styles.pciSeverityMark, style: { backgroundColor }, children: severityName }));
};
export const PciSeverityMarkInvalid = () => {
    return (_jsx("div", { className: styles.pciSeverityMark, style: { backgroundColor: rgbToHex(severityRgbColors[pciSeverityNames.failed]) }, children: "Invalid" }));
};
