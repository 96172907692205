export var PciExportDataRange;
(function (PciExportDataRange) {
    PciExportDataRange["all"] = "all";
    PciExportDataRange["viewport"] = "viewport";
})(PciExportDataRange || (PciExportDataRange = {}));
export var PciExportStats;
(function (PciExportStats) {
    PciExportStats["section"] = "section";
    PciExportStats["frameSegment"] = "framesegment";
})(PciExportStats || (PciExportStats = {}));
