import { toast } from "react-toastify";
export function filterByStepAndIndex({ element, array, stepSize, raiseErrorIfNotFound = false }) {
    const result = [];
    const indexOfCurrentId = array.indexOf(element);
    if (indexOfCurrentId === -1) {
        if (raiseErrorIfNotFound) {
            throw new Error('Current frame segment is not in sequence of ids');
        }
        else {
            toast.error('Current frame segment is not in sequence of ids');
            return [];
        }
    }
    const offset = indexOfCurrentId % stepSize;
    for (let i = offset; i < array.length; i += stepSize) {
        result.push(array[i]);
    }
    return result;
}
