import { demoUserLoginParams } from '../../const/userConstants';
import { staticServerOrganizationIds } from '../../const/staticServerOrganizationIds';
export function normalizeUser(res) {
    if (!res) {
        return {
            isDemoUser: false,
            isStaticUser: false,
            email: undefined,
            roles: [],
            folder: undefined,
            isAuthorized: false,
            organization_id: undefined,
            organization_name: undefined,
            defaultViewportConfig: undefined,
        };
    }
    return {
        isDemoUser: res.email === demoUserLoginParams.email,
        isStaticUser: staticServerOrganizationIds.includes(res.organization_id),
        email: res.email,
        roles: res.roles,
        /* @ts-ignore: todo */
        folder: res.folder_prefix || res.folder_name ? res.folder_prefix || res.folder_name : null,
        isAuthorized: true,
        organization_id: res.organization_id,
        organization_name: res.organization_name,
        defaultViewportConfig: res.default_viewport_config,
    };
}
// export function asAuthorizedUser(input: null | NormalizedUser): AuthorizedUser {
export function asAuthorizedUser(input) {
    if (!(input === null || input === void 0 ? void 0 : input.isAuthorized)) {
        throw new Error('Non authorized user passed');
    }
    return input;
}
