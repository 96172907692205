import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ButtonGroup } from '@blueprintjs/core/lib/esm/components/button/buttonGroup';
import { InputGroup, Button } from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core/lib/esm/common';
import { LoadingStatus } from '../../../../const/appConstants';
import { LoadingOverlay } from '../../../../components';
import styles from './FrameSegmentStatusControls.module.scss';
import { pciFeatureProcessingStatuses } from '../../../../const/pciConstants';
export const FrameSegmentStatusControls = ({ changeStatusLoadingStatus, onChangeFrameSegmentStatus, frameSegment, }) => {
    const [description, setDescription] = React.useState(frameSegment.properties.invalid_desc || '');
    const handleFsStatusChange = (newStatus) => {
        const patchBody = newStatus === 'invalid'
            ? { status: newStatus, description: description }
            : { status: newStatus };
        onChangeFrameSegmentStatus(patchBody);
    };
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };
    return (_jsxs("div", { className: styles.changeStatusContainer, children: [_jsxs(ButtonGroup, { children: [_jsx(Button, { onClick: () => handleFsStatusChange(pciFeatureProcessingStatuses.valid), text: "Valid", intent: frameSegment.properties.status === pciFeatureProcessingStatuses.valid ? Intent.PRIMARY : undefined }), _jsx(Button, { onClick: () => handleFsStatusChange(pciFeatureProcessingStatuses.hidden), text: "Hidden", intent: frameSegment.properties.status === pciFeatureProcessingStatuses.hidden ? Intent.PRIMARY : undefined }), _jsx(Button, { onClick: () => handleFsStatusChange(pciFeatureProcessingStatuses.invalid), text: "Invalid", intent: frameSegment.properties.status === pciFeatureProcessingStatuses.invalid ? Intent.PRIMARY : undefined })] }), _jsx(InputGroup, { placeholder: "Enter description", onChange: handleDescriptionChange, value: description, className: styles.descriptionInput }), _jsx("span", { children: changeStatusLoadingStatus === LoadingStatus.LOADING
                    ? _jsx(LoadingOverlay, {})
                    : null })] }));
};
