import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingOverlay, StoredViewportsListContainer, GettingStartedHintContainer, } from '../../../components';
import { PciDashboardContainer } from '../PciDashboard';
import { PciDataTypeFilters } from '../PciDataTypeFilters';
import { PciSeverityFilters } from '../PciSeverityFilters';
import { PciMenuContainer } from '../../pciMapTools/PciMenu';
import { PciViewerModal } from '../FSViewer/PciViewerModal';
import { useProfile } from '../../../hooks/useProfile';
import { navigateRoundArray } from '../../../utils/navigateRoundArray';
import { fetchAndApplyPciData, isSideResult } from './fetchAndApplyPciData';
import styles from './PciRegistry.module.scss';
import { pciFeatureProcessingStatuses } from '../../../const/pciConstants';
import { DEMO_VIEWPORT_CONFIG, WALTHAM_VIEWPORT_CONFIG, WORLD_VIEWPORT_CONFIG } from '../../../const/mapConstants';
import { PreferredMap } from '../../RoadLyMap/PreferredMap';
import { capitalizeString } from '../../../utils';
import { getViewportBoundsByViewState } from '../../../utils/viewportUtilities/getViewportBoundsByViewState';
import { FloatingContainer } from '../../../components/FloatingContainer';
import { useLoadedViewports } from '../../../hooks/useLoadedViewports';
import { useOuterPciViewerQaProps } from '../hooks/useOuterPciViewerQaProps';
import { DemoUserPciPanelContainer } from '../../pciMapTools/DemoUserPciPanel';
import { StickyContainer } from '../../../components/StickyContainer/StickyContainer';
import { LOCAL_STORAGE_KEYS } from '../../../const/localStorageConstants';
import { stableOrganizationIds } from '../../../const/stableOrganizationIds';
import { isStableEnv } from '../../../utils/isStableEnv';
import { usePciFeatureFilters } from '../../../hooks/pci.hooks/usePciFeatureFilters';
import { usePciData } from '../../../hooks/pci.hooks/usePciData';
import { API } from '../../../services/ApiService/api';
import { useFilteredFrameSegmentSequence } from '../../../hooks/pci.hooks/useFilteredFrameSegmentSequence';
import { userIsSuperAdmin as getUserIsSuperAdmin } from '../../../hooks/useRoles';
import { apiHelpers } from '../../../services/ApiService/api.helpers';
export const PciRegistryFeature = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pipelineId = searchParams.get('pipelineId');
    const userIsSuperAdmin = getUserIsSuperAdmin();
    const isUserAuthorized = apiHelpers.getIsAuthorized();
    /* Page level logic above */
    const { profile } = useProfile();
    const { allSampleUnitsData, allViewpointsData, allCoverageData, setAllSampleUnitsData, appendSampleUnitsData, setAllViewpointsData, appendViewpointsData, setAllCoverageData, isInitialDataState, } = usePciData();
    // eslint-disable-next-line no-nested-ternary
    const initialViewportConfig = profile.isDemoUser
        ? DEMO_VIEWPORT_CONFIG
        : isStableEnv() && profile.organization_id === stableOrganizationIds.waltham
            ? WALTHAM_VIEWPORT_CONFIG
            : WORLD_VIEWPORT_CONFIG;
    /*
    const initialViewportConfig = useMemo(() => {
      if (isInitialDataState) {
        // This has to be never shown, since map is not shown until data is loaded
        return WORLD_VIEWPORT_CONFIG;
      }
  
      const viewportConfigBasedOnPciData = getViewportConfigFromFeaturesLists([
        allViewpointsData?.features || [],
        allSampleUnitsData?.features || [],
      ]);
  
      if (viewportConfigBasedOnPciData) {
        return viewportConfigBasedOnPciData;
      }
  
      // If there is no data retrieved
      // try to show default viewport of user taken from server
      if (!profile.defaultViewportConfig) {
        return profile.defaultViewportConfig;
      }
  
      // If user has no default viewport, return default viewport app-wise
      return WORLD_VIEWPORT_CONFIG;
    }, [
      // allSampleUnitsData?.features,
      // allViewpointsData?.features,
      isInitialDataState,
      profile.defaultViewportConfig,
    ]);
    */
    // Need to wait until first pci loading
    // What is order of assigning config
    // There is viewport config assigned automatically
    // - From PCI map data
    // - default config from profile (this is initial)
    // -
    // And there is one assigned in hand mode
    //
    const [userViewportConfig, setUserViewportConfig] = useState(() => {
        /*
        * Parsing params from url
        */
        var _a, _b;
        // Try to parse viewport from URL
        if (searchParams.has('ll') && ((_a = searchParams.get('ll')) === null || _a === void 0 ? void 0 : _a.split(',').length) === 2 && searchParams.has('z')) {
            const ll = (_b = searchParams.get('ll')) === null || _b === void 0 ? void 0 : _b.split(',');
            return {
                longitude: Number(ll === null || ll === void 0 ? void 0 : ll[0]),
                latitude: Number(ll === null || ll === void 0 ? void 0 : ll[1]),
                zoom: Number(searchParams.get('z')),
            };
        }
        return initialViewportConfig;
    });
    const [selectedFrameSegmentId, setSelectedFrameSegmentId] = useState(() => {
        return searchParams.get('frameSegmentId');
    });
    // Update URL when viewport changes
    useEffect(() => {
        if (userViewportConfig) {
            const newParams = new URLSearchParams(searchParams);
            // set viewport params only if zoomed
            if (userViewportConfig.zoom > 1.5) {
                newParams.set('ll', `${Number(userViewportConfig.longitude.toFixed(6))},${Number(userViewportConfig.latitude.toFixed(6))}`);
                newParams.set('z', Number(userViewportConfig.zoom.toFixed(2)).toString());
            }
            else {
                newParams.delete('ll');
                newParams.delete('z');
            }
            setSearchParams(newParams);
        }
    }, [userViewportConfig, setSearchParams]);
    // Update URL when selected frame segment changes
    useEffect(() => {
        if (selectedFrameSegmentId) {
            const newParams = new URLSearchParams(searchParams);
            newParams.set('frameSegmentId', selectedFrameSegmentId);
            setSearchParams(newParams);
        }
        else if (searchParams.has('frameSegmentId')) {
            const newParams = new URLSearchParams(searchParams);
            newParams.delete('frameSegmentId');
            setSearchParams(newParams);
        }
    }, [selectedFrameSegmentId, setSearchParams]);
    /*
    * End of url params parsing
    */
    const [frameSegmentIdsSequence, setFrameSegmentIdsSequence] = useState([]);
    const [hasFetchAttempted, setHasFetchAttempted] = useState(false);
    const activeRequestsCount = useRef(0);
    const frameSegmentIdsToAdd = useRef(new Set());
    /**
     * Set up fetchers on start and
     * on panning/zooming action end
     */
    const [areFeaturesLoading, setAreFeaturesLoading] = useState(false);
    const { updateAndGetPolygon, removePolyFromLoadedPolys } = useLoadedViewports();
    const fetchAndApplyEffect = useCallback(async (zoom, boundingBox) => {
        var _a;
        if (zoom < 5) {
            return;
        }
        let multiPolygonToLoad = [];
        // What are cases when bounding box is not passed?
        if (boundingBox) {
            multiPolygonToLoad = updateAndGetPolygon([boundingBox]);
            if (multiPolygonToLoad.length === 0) {
                // Skip loading if nothing to load
                return;
            }
        }
        activeRequestsCount.current++;
        setAreFeaturesLoading(true);
        try {
            const result = await fetchAndApplyPciData({
                zoom,
                pipelineId,
                profile,
                appendViewpointsData: appendViewpointsData,
                appendSampleUnitsData: appendSampleUnitsData,
                geoAreaToRequest: multiPolygonToLoad,
            });
            if (isSideResult(result)) {
                return;
            }
            const { viewpointsFeatureCollection } = result;
            // Add new viewpoint IDs to the set
            ((_a = viewpointsFeatureCollection.features) !== null && _a !== void 0 ? _a : []).forEach((feature) => {
                frameSegmentIdsToAdd.current.add(feature.properties.id);
            });
        }
        catch (error) {
            console.error('Failed to load PCI data with geoAreaToRequest', multiPolygonToLoad);
            toast.error('Failed to load PCI data');
            removePolyFromLoadedPolys(multiPolygonToLoad);
        }
        finally {
            activeRequestsCount.current--;
            // If this is the last active request, update the sequence
            if (activeRequestsCount.current === 0) {
                setFrameSegmentIdsSequence(prevSequence => {
                    const newSequence = Array.from(new Set([...prevSequence, ...frameSegmentIdsToAdd.current])).sort();
                    frameSegmentIdsToAdd.current.clear();
                    return newSequence;
                });
                setAreFeaturesLoading(false);
            }
        }
    }, [pipelineId, profile, appendViewpointsData, appendSampleUnitsData]);
    // Also works on start, same behavior as useEffect
    const onNavigationEnd = (newViewportParams, boundingBox) => {
        setUserViewportConfig(newViewportParams);
        if (profile.isStaticUser && !isInitialDataState) {
            // Static data is fetched in the beginning
            // and has not to be supplemented during navigation
            return;
        }
        fetchAndApplyEffect(newViewportParams.zoom, boundingBox);
    };
    const { filteredSampleUnitsData, filteredViewpointsData, filteredCoverageData, viewpointsFilters, sampleUnitsFilters, isCoverageModeOn, pciViewModeKey, invalidPciVisibilityFilters, setInvalidPciVisibilityFilters, handleChangePciSeverityFilters, handleClickPciViewModeKey, handleClickCoverageMap, } = usePciFeatureFilters({ allSampleUnitsData, allViewpointsData, allCoverageData });
    /**
     * Pci viewer stuff
     */
    // Todo: replace any with proper type:
    const onClickMap = (info) => {
        var _a, _b;
        if (((_b = (_a = info.object) === null || _a === void 0 ? void 0 : _a.geometry) === null || _b === void 0 ? void 0 : _b.type) !== 'Point') {
            return;
        }
        setSelectedFrameSegmentId(info.object.properties.id);
    };
    const handlePciViewerClose = () => setSelectedFrameSegmentId(null);
    const storedStepSize = localStorage.getItem(LOCAL_STORAGE_KEYS.PCI_NAVIGATION_STEP);
    const navigationStepSize = storedStepSize === null ? 4 : Number(storedStepSize);
    let effectiveFrameSegmentIdsSequence = [];
    try {
        effectiveFrameSegmentIdsSequence = useFilteredFrameSegmentSequence(frameSegmentIdsSequence, selectedFrameSegmentId, navigationStepSize, true);
    }
    catch (error) {
        if (selectedFrameSegmentId && !areFeaturesLoading && !hasFetchAttempted && isUserAuthorized) {
            setHasFetchAttempted(true);
        }
    }
    // Reset hasFetchAttempted when selectedFrameSegmentId changes
    useEffect(() => {
        setHasFetchAttempted(false);
    }, [selectedFrameSegmentId]);
    // Failed to find frame segment id in sequence, try to request data near it
    // set viewport to it
    useEffect(() => {
        if (!hasFetchAttempted || !selectedFrameSegmentId) {
            return;
        }
        const zoom = 16;
        API.getMiniPciFrameSegment(selectedFrameSegmentId, userIsSuperAdmin).then((frame_segment_data) => {
            const [longitude, latitude] = frame_segment_data.viewpoint.geometry.coordinates;
            const boundingBox = getViewportBoundsByViewState({
                longitude,
                latitude,
                zoom,
            });
            onNavigationEnd({
                longitude: frame_segment_data.viewpoint.geometry.coordinates[0],
                latitude: frame_segment_data.viewpoint.geometry.coordinates[1],
                zoom,
            }, boundingBox);
        });
    }, [hasFetchAttempted]);
    const handlePciViewerNavigate = (direction) => {
        if (!selectedFrameSegmentId) {
            throw new Error('There is no current viewpoint');
        }
        const currentIndex = effectiveFrameSegmentIdsSequence.indexOf(selectedFrameSegmentId);
        const indexOfNewSelectedFrameSegmentId = navigateRoundArray({
            arrayLength: effectiveFrameSegmentIdsSequence.length,
            currentIndex,
            direction,
        });
        const newFrameSegmentId = effectiveFrameSegmentIdsSequence[indexOfNewSelectedFrameSegmentId];
        setSelectedFrameSegmentId(newFrameSegmentId);
    };
    /**
     * Stored viewports props
     */
    const handleSelectViewport = (newViewport) => {
        setUserViewportConfig({
            ...userViewportConfig,
            ...newViewport,
        });
    };
    /**
     * Common part
     */
    // const showBadPciAboveProps = useShowBadPciAbove();
    const showBadPciAbove = true;
    const miniPciIndexConfig = {
        // showBadPciAbove: showBadPciAboveProps.showBadPciAbove,
        showBadPciAbove,
        viewpointsData: filteredViewpointsData,
        sampleUnitsData: filteredSampleUnitsData,
        coverageData: filteredCoverageData,
        getTooltip: ({ object }) => {
            if (!object) {
                return null;
            }
            let text = '';
            if (object.properties.status === pciFeatureProcessingStatuses.invalid) {
                text = object.properties.invalid_desc || 'Description not passed';
            }
            else {
                text = `${capitalizeString(object.properties.stats.pciSeverityName)} / ${object.properties.stats.pci.toFixed(0)}`;
            }
            return {
                text,
                style: {
                    marginLeft: '10px',
                },
            };
        },
    };
    const mapConfig = {
        onClickMap,
        onNavigationEnd,
        userViewportConfig,
        miniPciIndexConfig,
    };
    /**
     * PCI QA stuff
     */
    const outerPciViewerQaProps = useOuterPciViewerQaProps({ pipelineId, selectedFrameSegmentId });
    return (_jsxs("div", { className: styles.miniPciContainer, children: [_jsxs(StickyContainer, { position: "topRight", stickContent: "topRight", isPadded: true, children: [profile.isDemoUser ? (_jsx(DemoUserPciPanelContainer, {})) : null, _jsx(StoredViewportsListContainer, { activateViewport: handleSelectViewport }), _jsx(PciMenuContainer, {})] }), _jsx(GettingStartedHintContainer, { className: styles.gettingStartedHintContainer }), selectedFrameSegmentId && (_jsx(PciViewerModal, { frameSegmentId: selectedFrameSegmentId, onClose: handlePciViewerClose, onNavigate: handlePciViewerNavigate, navigationStepSize: navigationStepSize, isFrameSegmentIdsSequenceLoaded: !areFeaturesLoading, frameSegmentIdsSequence: effectiveFrameSegmentIdsSequence, outerPciViewerQaProps: outerPciViewerQaProps })), _jsx(PciDashboardContainer, { className: styles.pciDashboardContainer, tmpViewpointsData: allViewpointsData }), _jsx(PciSeverityFilters, { className: styles.pciSeverityFilters, onChangePciSeverityFilters: handleChangePciSeverityFilters, sampleUnitsFilters: sampleUnitsFilters, viewpointsFilters: viewpointsFilters, pciViewModeKey: pciViewModeKey, onChangeInvalidPciVisibilityFilters: setInvalidPciVisibilityFilters, invalidPciVisibilityFilters: invalidPciVisibilityFilters }), _jsx(PciDataTypeFilters, { onClickPciViewModeKey: handleClickPciViewModeKey, onClickCoverageMap: handleClickCoverageMap, className: styles.pciDataTypeFilters, isCoverageMapOn: isCoverageModeOn, pciViewModeKey: pciViewModeKey }), !areFeaturesLoading ? null : (_jsx(FloatingContainer, { className: styles.loadingContainer, children: _jsx(LoadingOverlay, {}) })), _jsx(PreferredMap, { className: styles.map, ...mapConfig })] }));
};
