import React, { useEffect } from 'react';
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { LOCAL_STORAGE_KEYS } from 'const'
import { useDialog } from 'hooks/useDialog'
import useProfile from 'hooks/useProfile'
import { Navigate, useLocation } from 'react-router-dom'

export const PrivatePage = ({ children }) => {
  const location = useLocation()
  const { profile } = useProfile()
  const { show, dialogsTypes } = useDialog()

  // Show welcome dialog on the first visit
  useEffect(() => {
    if (profile.isAuthorized) {
      const hasWelcomeDialogToBeShown = !Boolean(localStorage.getItem(LOCAL_STORAGE_KEYS.IS_WELCOME_DIALOG_SHOWN_V_3))
      if (hasWelcomeDialogToBeShown) {
        show(dialogsTypes.WELCOME)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!profile.isAuthorized) {
    return <Navigate to={PATH.signIn.path} state={{ from: location }} replace />
  }

  return children
}
