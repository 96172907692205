import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { StoredViewportsList } from './StoredViewportsList';
import { loadStoredViewpointPayloadsList, saveStoredViewpointsList, } from './StoredViewportsListUtilities';
import { RoadLyMapContext } from '../../contexts/roadLyMapContext';
import { toast } from 'react-toastify';
export const StoredViewportsListContainer = ({ activateViewport, }) => {
    const [{ viewState }] = useContext(RoadLyMapContext);
    const [storedViewportPayloads, setStoredViewportPayloads] = useState([]);
    useEffect(() => {
        const loadedStoredViewportPayloads = loadStoredViewpointPayloadsList();
        setStoredViewportPayloads(loadedStoredViewportPayloads);
    }, []);
    const addViewportPayload = async (name) => {
        if (!name.length) {
            toast.error('Enter a name for the viewport');
            return false;
        }
        const { latitude, longitude, zoom } = viewState;
        const newViewportPayload = {
            name,
            viewport: { latitude, longitude, zoom },
        };
        const updatedPayloadsList = [...storedViewportPayloads, newViewportPayload];
        saveStoredViewpointsList(updatedPayloadsList);
        setStoredViewportPayloads(updatedPayloadsList);
        return true;
    };
    const removeSavedViewport = async (viewportPayload) => {
        const updatedPayloadsList = storedViewportPayloads.filter((el) => el !== viewportPayload);
        saveStoredViewpointsList(updatedPayloadsList);
        setStoredViewportPayloads(updatedPayloadsList);
    };
    const handleClickViewport = (viewportPayload) => {
        activateViewport(viewportPayload.viewport);
    };
    return (_jsx(StoredViewportsList, { onClickOnViewport: handleClickViewport,
        storedViewportPayloads,
        addViewportPayload,
        removeSavedViewport }));
};
