import { useContext } from 'react';
import { ProfileContext } from '../contexts/profileContext';
import { ROLES } from '../const/userConstants';
export const useRoles = () => {
    const [profile] = useContext(ProfileContext);
    if (profile === null) {
        throw new Error('Not authorized app state');
    }
    return profile.roles;
};
export const userIsSuperAdmin = () => {
    const roles = useRoles();
    return roles ? roles.includes(ROLES.SUPERADMIN) : false;
};
export const userIs = (role) => {
    const roles = useRoles();
    return roles ? roles.includes(role) || roles.includes(ROLES.SUPERADMIN) : false;
};
