export const LOCAL_STORAGE_KEYS = {
    PIPELINE_LIST_SORT_MAP: 'PIPELINE_LIST_SORT_MAP',
    SESSIONS_LIST_SORT_MAP: 'SESSIONS_LIST_SORT_MAP',
    IS_WELCOME_DIALOG_SHOWN_V_3: 'IS_WELCOME_DIALOG_SHOWN_V_3',
    LAST_GETTING_STARTED_HINT_PAGE_NUMBER: 'LAST_GETTING_STARTED_HINT_PAGE_NUMBER',
    IMAGE_VIEW_TYPE: 'IMAGE_VIEW_TYPE',
    IS_GETTING_STARTED_HINT_VISIBLE: 'IS_GETTING_STARTED_HINT_VISIBLE',
    TMP_ADDRESS_LABEL: 'TMP_ADDRESS_LABEL',
    STORED_VIEWPOINTS_LIST: 'STORED_VIEWPOINTS_LIST',
    IS_PCI_DEBUG_MODE_ON: 'IS_PCI_DEBUG_MODE_ON',
    IS_API_CACHE_SERVICE_ON: 'IS_API_CACHE_SERVICE_ON',
    APP_BRAND: 'APP_BRAND',
    PCI_NAVIGATION_STEP: 'PCI_NAVIGATION_STEP',
};
