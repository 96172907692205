import { useState, useEffect } from 'react'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { ButtonGroup, Button } from '@blueprintjs/core'
import { DateRangeInput2 } from "@blueprintjs/datetime2"
import styles from './SessionsFilters.module.css'

const DEFAULT_STATE = {
  owner: null,
  createdDateRange: undefined,
}

const DATE_FORMAT_API = 'yyyy-MM-dd HH:mm'
const DATE_FORMAT_UI = 'dd.MM.yyyy HH:mm'

const _formatDate = (fmt, date) => (date ? format(date, fmt) : undefined)
const _parseDate = (fmt, str) => (str ? parse(str, fmt, new Date()) : null)
const formatDate = _formatDate.bind(null, DATE_FORMAT_API)
const parseDate = _parseDate.bind(null, DATE_FORMAT_API)
const formatDateUI = _formatDate.bind(null, DATE_FORMAT_UI)
const parseDateUI = _parseDate.bind(null, DATE_FORMAT_UI)

export default function SessionsFilters({
  owner: defaultOwner,
  createdFrom: defaultCreatedFrom,
  createdTo: defaultCreatedTo,
  applyFilters: defaultApplyFilters,
}) {
  const [state, setState] = useState({ ...DEFAULT_STATE })
  const { owner } = state
  const applyFilters = state => {
    defaultApplyFilters({
      owner: state.owner,
      ...(state.createdDateRange && {
        created_from: formatDate(state.createdDateRange[0]),
        created_to: formatDate(state.createdDateRange[1]),
      }),
    })
  }

  useEffect(() => {
    if (defaultCreatedFrom === null && defaultCreatedTo !== null)
      return setState({
        ...state,
        createdDateRange: undefined,
        owner: defaultOwner,
      })
    setState({
      ...state,
      createdDateRange: [parseDate(defaultCreatedFrom), parseDate(defaultCreatedTo)],
      owner: defaultOwner,
    })
  }, [defaultOwner, defaultCreatedFrom, defaultCreatedTo])

  const setOnwer = newOwner => {
    if (state.owner === newOwner) return
    const newState = { ...state, owner: newOwner }
    setState(newState)
    applyFilters(newState)
  }

  const setCreatedRange = createdDateRange => {
    const newState = { ...state, createdDateRange }
    setState(newState)
    applyFilters(newState)
  }

  const resetCreatedDateRange = () => {
    const newState = {
      ...state,
      createdDateRange: undefined,
    }
    setState(newState)
    applyFilters(newState)
  }

  const dateInputProps = {
    small: true,
    fill: true,
  }
  return (
    <>
      <h4 className="text bp4-text-small bp4-text-muted">Owner</h4>
      <ButtonGroup>
        <Button text="me" active={owner === 'me'} onClick={() => setOnwer('me')} />
        <Button text="all" active={owner === 'all'} onClick={() => setOnwer('all')} />
      </ButtonGroup>
      <h4 className="text bp4-text-small bp4-text-muted">Creation date (UTC)</h4>
      <div className={styles.dateRange}>
        <DateRangeInput2
          allowSingleDayRange={true}
          startInputProps={dateInputProps}
          endInputProps={dateInputProps}
          includeTime={true}
          formatDate={formatDateUI}
          parseDate={parseDateUI}
          onChange={setCreatedRange}
          value={state.createdDateRange}
        />
        <Button className={styles.dateRangeReset} onClick={resetCreatedDateRange} icon="reset" small={true} />
      </div>
    </>
  )
}
