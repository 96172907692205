import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider } from '@blueprintjs/core/lib/esm/components';
import { Button, Icon } from '@blueprintjs/core';
import { ImageViewType } from '../../../../const/pciConstants';
import { PciSeverityMark, PciSeverityMarkInvalid } from '../../PciSeverityMark';
import { PciViewerDetails } from '../PciViewerDetails';
import { PciViewerViewport } from '../PciViewerViewport';
import styles from './PciViewer.module.scss';
import { LoadingOverlay } from '../../../../components';
import { SwitchViewsButtons } from '../SwitchViewsButtons';
import { PointsNavigation } from '../../PointsNavigation';
import { PciViewerQaControlsContainer } from '../PciViewerQaControls/PciViewerQaControlsContainer';
import { usePciFSControls } from '../../hooks/usePciFSControls';
import { FrameSegmentStatusControlsContainer } from '../FrameSegmentStatusControls';
import { ContentFor } from '../../../../components/ContentFor';
import { ROLES } from '../../../../const/userConstants';
import { userIsSuperAdmin } from '../../../../hooks/useRoles';
import { copyWithToast } from '../../../../utils/copyWithToast/copyWithToast';
export const PciViewer = ({ frameSegmentId, onRequestReloadFrameSegment, aggregatedFrameSegmentPreloadingResult, imageViewType, onChangeViewType, onNavigate, isFrameSegmentLoading, isFrameSegmentIdsSequenceLoaded, outerPciViewerQaProps, }) => {
    const pciFSControlsProps = usePciFSControls({ onNavigate });
    // Frame segment is either loading or failed to load
    // TODO: better handle errors
    if (aggregatedFrameSegmentPreloadingResult === null || aggregatedFrameSegmentPreloadingResult.frameSegmentResponseBody === null) {
        if (isFrameSegmentLoading) {
            return (_jsx("div", { className: styles.pciViewerContainer, children: _jsx(LoadingOverlay, { children: _jsxs("div", { children: ["Please wait, the images are loading", _jsx("br", {}), "This may sometimes take a while"] }) }) }));
        }
        return (_jsxs("div", { className: styles.pciViewerContainer, children: [_jsxs("div", { className: styles.errorMessage, children: ["Frame segment failed to load", _jsx("br", {}), " ", _jsx("br", {}), _jsxs("code", { className: styles.copyableCode, onClick: () => copyWithToast(frameSegmentId, 'Frame segment ID copied to clipboard'), children: [frameSegmentId, " ", _jsx(Icon, { icon: "duplicate" })] })] }), _jsx("div", { className: styles.commonButtonsContainer, children: _jsx("div", { className: styles.navigationButtonsContainer, style: { display: 'flex', justifyContent: 'center', width: '100%' }, children: isFrameSegmentIdsSequenceLoaded
                            ? _jsx(PointsNavigation, { onNavigate: onNavigate })
                            : _jsx("div", { children: "Loading frame sequence..." }) }) })] }));
    }
    const { frameSegmentResponseBody: { distresses, viewpoint }, frameSegmentImagesPreloadingResult, } = aggregatedFrameSegmentPreloadingResult;
    const { pci, area } = viewpoint.properties.stats;
    if (viewpoint.properties.status === 'invalid' && !userIsSuperAdmin()) {
        pciFSControlsProps.isRoiMaskVisible = false;
        pciFSControlsProps.isDistressVisible = false;
        if (imageViewType === ImageViewType.bev) {
            onChangeViewType(ImageViewType.front);
        }
        return (_jsxs("div", { className: styles.pciViewerContainer, children: [isFrameSegmentLoading ? _jsx(LoadingOverlay, {}) : null, _jsxs("div", { className: styles.topLine, children: [_jsxs("div", { className: styles.pciNumberContainer, children: [_jsx("strong", { children: viewpoint.properties.invalid_desc }), "\u00A0\u00A0", _jsx(PciSeverityMarkInvalid, {})] }), _jsx("div", { className: styles.headerPlaceholder, children: "\u00A0" })] }), _jsx("div", { className: styles.viewportBlock, children: _jsx(PciViewerViewport, { pciFSControlsProps: pciFSControlsProps, viewpoint: viewpoint, isFrameSegmentLoading: isFrameSegmentLoading, frameSegmentImagesPreloadingResult: frameSegmentImagesPreloadingResult, distresses: distresses, imageViewType: imageViewType }) }), _jsx("div", { className: styles.commonButtonsContainer, children: _jsx("div", { className: styles.navigationButtonsContainer, children: isFrameSegmentIdsSequenceLoaded
                            ? _jsx(PointsNavigation, { onNavigate: onNavigate })
                            : _jsx("div", { children: "Loading frame sequence..." }) }) }), _jsx(PciViewerDetails, { viewpoint: viewpoint })] }));
    }
    return (_jsxs("div", { className: styles.pciViewerContainer, children: [isFrameSegmentLoading ? _jsx(LoadingOverlay, {}) : null, _jsxs("div", { className: styles.topLine, children: [_jsxs("div", { className: styles.pciNumberContainer, children: ["PCI:\u00A0", _jsx("strong", { children: Number(pci.toFixed(2)).toString() }), "\u00A0\u00A0", _jsx(PciSeverityMark, { pci: pci })] }), _jsx("div", { className: styles.headerPlaceholder, children: "\u00A0" })] }), _jsx("div", { className: styles.viewportBlock, children: _jsx(PciViewerViewport, { pciFSControlsProps: pciFSControlsProps, viewpoint: viewpoint, isFrameSegmentLoading: isFrameSegmentLoading, frameSegmentImagesPreloadingResult: frameSegmentImagesPreloadingResult, distresses: distresses, imageViewType: imageViewType }) }), _jsxs("div", { className: styles.commonButtonsContainer, children: [_jsx("div", { className: styles.navigationButtonsContainer, children: isFrameSegmentIdsSequenceLoaded
                            ? _jsx(PointsNavigation, { onNavigate: onNavigate })
                            : _jsx("div", { children: "Loading frame sequence..." }) }), _jsxs("div", { className: styles.viewModeButtonContainer, children: [_jsx(SwitchViewsButtons, { imageViewType: imageViewType, onChangeViewType: onChangeViewType }), _jsx(Button, { small: true, onClick: pciFSControlsProps.toggleRoiMask, children: "ROI (r)" }), _jsx(Button, { small: true, onClick: pciFSControlsProps.toggleDistress, children: "Distresses (d)" })] })] }), outerPciViewerQaProps ? (_jsxs(_Fragment, { children: [_jsx(PciViewerQaControlsContainer, { onNavigate: onNavigate, ...outerPciViewerQaProps }), _jsx(Divider, {})] })) : null, _jsx(ContentFor, { role: ROLES.SUPERADMIN, children: _jsx(FrameSegmentStatusControlsContainer, { frameSegment: viewpoint, onRequestReloadFrameSegment: onRequestReloadFrameSegment }) }), _jsx(PciViewerDetails, { viewpoint: viewpoint })] }));
};
