import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Dialog } from '@blueprintjs/core/lib/esm/components/dialog/dialog';
import { Button } from '@blueprintjs/core/lib/esm/components/button/buttons';
import { Classes } from '@blueprintjs/core/lib/esm/common';
import { Divider } from '@blueprintjs/core/lib/esm/components';
import styles from './StoredViewportsList.module.scss';
export const StoredViewportsList = ({ onClickOnViewport, storedViewportPayloads, addViewportPayload, removeSavedViewport, }) => {
    const [isAddPayloadDialogOpen, setIsAddPayloadDialogOpen] = useState(false);
    const [newPayloadName, setNewPayloadName] = useState('');
    const [isListExpanded, setIsListExpanded] = useState(false);
    const handleInputUpdate = (event) => {
        setNewPayloadName(event.target.value);
    };
    const handleAddViewport = async () => {
        const isPayloadAdded = await addViewportPayload(newPayloadName);
        if (isPayloadAdded) {
            setIsAddPayloadDialogOpen(false);
            setNewPayloadName('');
        }
    };
    return (_jsxs("div", { className: styles.viewportsList, children: [_jsxs(Dialog, { isOpen: isAddPayloadDialogOpen, className: styles.addDialog, children: [_jsxs("div", { className: Classes.DIALOG_HEADER, children: [_jsx("h5", { className: "bp4-heading", children: "Add saved viewpoint" }), _jsx(Button, { icon: "cross", className: Classes.DIALOG_CLOSE_BUTTON, onClick: () => setIsAddPayloadDialogOpen(false) })] }), _jsxs("div", { className: styles.addDialogContent, children: [_jsx("div", { children: "Type in name to save current viewport position:" }), _jsx("input", { 
                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                autoFocus: true, className: "bp4-input", onChange: handleInputUpdate, value: newPayloadName }), _jsx("br", {}), _jsx(Button, { onClick: handleAddViewport, children: "Add" })] })] }), _jsxs("div", { children: [_jsx(Button, { icon: "bookmark", minimal: true, fill: true, 
                        // className={styles.listItem}
                        onClick: () => setIsListExpanded(!isListExpanded), children: "Stored viewports" }), isListExpanded ? (_jsxs(_Fragment, { children: [_jsx(Divider, {}), storedViewportPayloads.length
                                ? (_jsxs(_Fragment, { children: [storedViewportPayloads.map((el) => (_jsxs("div", { className: styles.listItem, children: [_jsx("div", { className: styles.listItemName, onClick: () => onClickOnViewport(el), children: el.name }), _jsx(Button, { minimal: true, icon: "cross", onClick: () => removeSavedViewport(el) })] }, el.name))), _jsx(Divider, {})] }))
                                : null, _jsx("div", { className: styles.addButtonContainer, children: _jsx(Button, { icon: "add", minimal: true, fill: true, onClick: () => setIsAddPayloadDialogOpen(true), children: "Add" }) })] })) : null] })] }));
};
