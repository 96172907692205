import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover2 as Popover, Classes as PopoverClasses } from '@blueprintjs/popover2';
import { Icon, Menu, MenuDivider, MenuItem, Switch } from '@blueprintjs/core';
import { apiCacheKeys } from '../../../services/ApiCacheService/ApiCacheService';
export const CacheControl = ({ isCacheServiceOn, onClickSwitchCache, onClickInvalidate, }) => {
    //
    const handleChange = (a) => {
        onClickSwitchCache();
        console.log('Okay:', { a });
    };
    return (_jsx(Popover, { placement: "right", popoverClassName: PopoverClasses.POPOVER2_CONTENT_SIZING, hoverCloseDelay: 500, content: (_jsxs(Menu, { children: [_jsx(MenuItem, { text: (_jsx(Switch, { checked: isCacheServiceOn, onChange: handleChange, labelElement: "Api cache service on" })) }), _jsx(MenuDivider, {}), _jsx(MenuItem, { text: "Clear flat sessions list cache", onClick: () => onClickInvalidate(apiCacheKeys.getSessions) }), _jsx(MenuDivider, {}), _jsx(MenuItem, { text: "Clear assets (+ extended sessions) cache", onClick: () => onClickInvalidate(apiCacheKeys.extendedSessions) }), _jsx(MenuDivider, {}), _jsx(MenuItem, { text: "Clear PCI map data cache", onClick: () => onClickInvalidate(apiCacheKeys.pciMapData) }), _jsx(MenuDivider, {}), _jsx(MenuItem, { text: "Clear pipelines list cache", onClick: () => onClickInvalidate(apiCacheKeys.getPipelinesList) })] })), children: _jsx(Icon, { icon: "bring-data" }) }));
};
