import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { PciViewer } from './PciViewer';
import { LOCAL_STORAGE_KEYS } from '../../../../const/localStorageConstants';
import { FrameNavigationDirection, ImageViewType } from '../../../../const/pciConstants';
import { FrameSegmentBatchPreloader } from '../../../../utils/AggregatedFrameSegmentPreloader';
import { useFilteredFrameSegmentSequence } from '../../../../hooks/pci.hooks/useFilteredFrameSegmentSequence';
import { userIsSuperAdmin } from '../../../../hooks/useRoles';
// Has to be async
// Todo: update param type or event stop using?
// Handle old values
function getActualizedStoredImageViewType() {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEYS.IMAGE_VIEW_TYPE);
    if (storedValue === null) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.IMAGE_VIEW_TYPE, ImageViewType.front);
        return ImageViewType.front;
    }
    // Handle two old values
    if (storedValue === 'FRONT_VIEW') {
        localStorage.setItem(LOCAL_STORAGE_KEYS.IMAGE_VIEW_TYPE, ImageViewType.front);
        return ImageViewType.front;
    }
    if (storedValue === 'BIRD_EYE_VIEW') {
        localStorage.setItem(LOCAL_STORAGE_KEYS.IMAGE_VIEW_TYPE, ImageViewType.bev);
        return ImageViewType.bev;
    }
    // Handle actual values
    if (Object.keys(ImageViewType).includes(storedValue)) {
        return ImageViewType[storedValue];
    }
    // Default
    localStorage.setItem(LOCAL_STORAGE_KEYS.IMAGE_VIEW_TYPE, ImageViewType.front);
    return ImageViewType.front;
}
function getInitialViewType() {
    const storedValue = getActualizedStoredImageViewType();
    if (storedValue) {
        return storedValue;
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.IMAGE_VIEW_TYPE, ImageViewType.front);
    return ImageViewType.front;
}
/**
 * Todo: normalized viewpoint has to include links to next and previous viewpoints
 */
export const PciViewerContainer = ({ frameSegmentId, onNavigate, navigationStepSize, frameSegmentIdsSequence, outerPciViewerQaProps, isFrameSegmentIdsSequenceLoaded, }) => {
    const [isModalJustOpened, setIsModalJustOpened] = useState(true);
    const isSuperAdmin = userIsSuperAdmin();
    const frameSegmentBatchPreloader = useMemo(() => new FrameSegmentBatchPreloader({
        frameSegmentIdsSequence: [],
        asSuperAdmin: isSuperAdmin
    }), [isSuperAdmin]);
    const [aggregatedFrameSegmentPreloadingResult, setAggregatedFrameSegmentPreloadingResult] = useState(null);
    const [isFrameSegmentLoading, setIsFrameSegmentLoading] = useState(false);
    const [imageViewType, setViewType] = useState(getInitialViewType());
    const handleChangeViewType = (newViewType) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.IMAGE_VIEW_TYPE, newViewType);
        setViewType(newViewType);
    };
    let effectiveFrameSegmentIdsSequence = [];
    try {
        effectiveFrameSegmentIdsSequence = useFilteredFrameSegmentSequence(frameSegmentIdsSequence, frameSegmentId, navigationStepSize, true);
    }
    catch (error) {
    }
    const handleRequestReloadFrameSegment = async () => {
        setIsFrameSegmentLoading(true);
        console.log('handleRequestReloadFrameSegment.1');
        const newPreloadingResult = await frameSegmentBatchPreloader.initQueuedPreloadingAnGetFirstOne({
            id: frameSegmentId,
            imageViewType,
            direction: FrameNavigationDirection.FORWARD,
            forceReloadFrameSegment: true,
        });
        console.log('handleRequestReloadFrameSegment.2');
        setIsFrameSegmentLoading(false);
        setAggregatedFrameSegmentPreloadingResult(newPreloadingResult);
    };
    useEffect(() => {
        setIsModalJustOpened(false);
        (async () => {
            setIsFrameSegmentLoading(true);
            // Todo: return memoized or maybe leave like this
            frameSegmentBatchPreloader.frameSegmentIdsSequence = effectiveFrameSegmentIdsSequence.length
                ? effectiveFrameSegmentIdsSequence
                : [frameSegmentId];
            const newPreloadingResult = await frameSegmentBatchPreloader.initQueuedPreloadingAnGetFirstOne({
                id: frameSegmentId,
                imageViewType,
                direction: FrameNavigationDirection.FORWARD,
                forceReloadFrameSegment: false,
            });
            setAggregatedFrameSegmentPreloadingResult(newPreloadingResult);
            setIsFrameSegmentLoading(false);
        })();
    }, [frameSegmentId, imageViewType]);
    /**
     * Preparing QA props
     */
    return (_jsx(PciViewer, { frameSegmentId,
        onRequestReloadFrameSegment: handleRequestReloadFrameSegment,
        aggregatedFrameSegmentPreloadingResult,
        isFrameSegmentLoading,
        isFrameSegmentIdsSequenceLoaded,
        imageViewType,
        onChangeViewType: handleChangeViewType,
        onNavigate,
        outerPciViewerQaProps }));
};
