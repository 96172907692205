import React, { useState, useRef } from 'react'
import isUndefined from 'lodash/isUndefined'

import { VideoPlayer } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/VideoPlayer'
import FullScreenWrapper from 'components/PipelineItem/FullScreenWrapper'
import PipelineItemContent from 'components/PipelineItemContent/PipelineItemContent'
import PipelineItemSidebar from 'components/PipelineItemSidebar/PipelineItemSidebar'
import utils from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/utils'
import useWindow from 'hooks/useWindow'
import {
  POINT_CLOUD_TYPE,
  TILE_OPTIONS,
  TILE_OPTIONS_LIST,
  DEFAULT_POINT_SIZE,
  DEFAULT_POSES_STEP_SIZE,
  PATH_OPTIONS,
} from 'const'
import { SEVERITY_CLASS_OPTIONS } from 'utils/pci'

function PipelineItem({ pipeline, viewport, track, pointCloud, poses, pciData, pciFileUrl, cameraLocalization }) {
  const { isDesktop } = useWindow()

  const pipelineVideoFps = utils.getVideoMetadata(pipeline).fps
  const output = utils.getAllOutput(pipeline)

  const sourceVideoOutput = output.find(({ url }) => url.includes('source_video.mp4'))

  const hasTrack = !isUndefined(track)
  const hasPoses = !isUndefined(poses)
  const hasPCI = !isUndefined(pciData)
  const hasPointCloud = !isUndefined(pointCloud)

  const tilesOptions = hasPointCloud
    ? TILE_OPTIONS_LIST.filter(({ outputType }) => {
        return Boolean(pointCloud.tiles[outputType])
      })
    : TILE_OPTIONS_LIST

  const pathOptions = []

  if (hasTrack) {
    pathOptions.push(PATH_OPTIONS.GPS)
  }
  if (hasPoses) {
    pathOptions.push(PATH_OPTIONS.POSES)
  }

  const playerRef = useRef(undefined)

  const defaultTilesCurrent = tilesOptions.find(({ value }) => value === TILE_OPTIONS.NATURAL_COLORS.value)
    ? TILE_OPTIONS.NATURAL_COLORS
    : tilesOptions[0]

  // Control level
  const [isPathVisible, setIsPathVisible] = useState(hasTrack || hasPoses)

  // What path to show, pipeline viewer
  // Controlled what path to choose
  const [pathCurrent, setPathCurrent] = useState(pathOptions[pathOptions.length - 1])

  const [tilesCurrent, setTilesCurrent] = useState(defaultTilesCurrent)
  const [pointSize, setPointSize] = useState(DEFAULT_POINT_SIZE)
  const [posesStepSize, setPosesStepSize] = useState(DEFAULT_POSES_STEP_SIZE)
  const [isPointCloudVisible, setIsPointCloudVisible] = useState(hasPointCloud)
  const [semanticFilterClasses, setSemanticFilterClasses] = useState(null)
  const [isVideoFullscreen, setIsVideoFullscreen] = useState(false)
  const [videoPoses, setVideoPoses] = useState(null)
  const [isPCICrackVisible, setPCICrackVisible] = useState(hasPCI)
  const [isPCIPotholeVisible, setPCIPotholeVisible] = useState(false)
  const [selectedPCISeverityClasses, setSelectedPCISeverityClasses] = useState(SEVERITY_CLASS_OPTIONS.map(() => true))

  const toggleFullscreen = () => {
    setIsVideoFullscreen(!isVideoFullscreen)
  }

  const onPathChange = path => {
    setPathCurrent(path)
  }

  const onTailsChange = tiles => {
    setTilesCurrent(tiles)
  }

  const onSemanticFilterClassesChange = classes => setSemanticFilterClasses(classes)

  const onPointCloudVisibleChange = value => {
    setIsPointCloudVisible(value)
  }

  const onPointSizeChange = value => {
    setPointSize(value)
  }

  const onPosesStepSizeChange = value => {
    setPosesStepSize(value)
  }

  const onPathVisibleChange = value => {
    setIsPathVisible(value)
  }

  const onPosesClick = time => {
    playerRef.current.currentTime(time)
  }

  const filteredPoses = poses ? poses.filter((p, i) => i % posesStepSize === 0) : null

  const onTimeUpdated = time => {
    const currentTime = time * pipelineVideoFps

    const item =
      filteredPoses &&
      filteredPoses.find(
        (pose, index, arr) =>
          Math.abs(currentTime - pose.time) < (arr[index + 1] ? Math.abs(currentTime - arr[index + 1].time) : Infinity)
      )

    setVideoPoses(item)
  }

  return (
    <>
      <FullScreenWrapper text="Map" fullscreen={!isVideoFullscreen} toggleFullscreen={toggleFullscreen}>
        <PipelineItemContent
          pipeline={pipeline}
          viewport={viewport}
          hasPath={hasTrack || hasPoses}
          isPointCloudVisible={isPointCloudVisible}
          isPathVisible={isPathVisible}
          isSettingsActive={!isVideoFullscreen}
          track={track}
          poses={filteredPoses}
          pciData={pciData}
          isPCICrackVisible={isPCICrackVisible}
          selectedPCISeverityClasses={selectedPCISeverityClasses}
          cameraLocalization={cameraLocalization}
          pointCloud={pointCloud}
          pointSize={pointSize}
          fps={pipelineVideoFps}
          pathCurrent={pathCurrent}
          tilesCurrent={tilesCurrent}
          semanticFilterClasses={semanticFilterClasses}
          videoPoses={videoPoses}
          onPosesClick={onPosesClick}
        />
        <PipelineItemSidebar
          pipeline={pipeline}
          hasPath={hasTrack || hasPoses}
          hasTiles={pointCloud && pointCloud.type === POINT_CLOUD_TYPE.TILES}
          hasPointCloud={hasPointCloud}
          hasPCI={hasPCI}
          pciFileUrl={pciFileUrl}
          isPCICrackVisible={isPCICrackVisible}
          setPCICrackVisible={setPCICrackVisible}
          isPCIPotholeVisible={isPCIPotholeVisible}
          setPCIPotholeVisible={setPCIPotholeVisible}
          selectedPCISeverityClasses={selectedPCISeverityClasses}
          setSelectedPCISeverityClasses={setSelectedPCISeverityClasses}
          isShow={!isVideoFullscreen}
          isPointCloudVisible={isPointCloudVisible}
          isPathVisible={isPathVisible}
          pathCurrent={pathCurrent}
          tilesCurrent={tilesCurrent}
          pathOptions={pathOptions}
          tilesOptions={tilesOptions}
          pointSize={pointSize}
          posesStepSize={posesStepSize}
          onPathChange={onPathChange}
          onTailsChange={onTailsChange}
          onPointSizeChange={onPointSizeChange}
          onPosesStepSizeChange={onPosesStepSizeChange}
          onPathVisibleChange={onPathVisibleChange}
          onPointCloudVisibleChange={onPointCloudVisibleChange}
          onSemanticFilterClassesChange={onSemanticFilterClassesChange}
        />
      </FullScreenWrapper>
      {sourceVideoOutput && (
        <FullScreenWrapper
          text="Video"
          initialOpen={isDesktop}
          fullscreen={isVideoFullscreen}
          toggleFullscreen={toggleFullscreen}
        >
          <VideoPlayer
            url={sourceVideoOutput.url}
            playerRef={playerRef}
            onTimeUpdated={onTimeUpdated}
            options={{controlBar: {fullscreenToggle: false}}}
          />
        </FullScreenWrapper>
      )}
    </>
  )
}

export default PipelineItem
