import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import JSONEditor from 'jsoneditor';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
export function JsonEditor({ text, onChangeText }) {
    const _ref = useRef(null);
    const [editor, setEditor] = useState();
    useEffect(() => {
        if (editor && text) {
            editor.updateText(text);
        }
    }, [text, editor]);
    useEffect(() => {
        const options = {
            mode: 'code',
            modes: ['tree', 'code'],
            onChangeText,
            theme: 'ace/theme/tomorrow_night'
        };
        const newEditor = new JSONEditor(_ref.current, options);
        setEditor(newEditor);
        return () => {
            newEditor.destroy();
        };
    }, []); //eslint-disable-line
    return _jsx("div", { ref: _ref, style: { height: '60vh' } });
}
