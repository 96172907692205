import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { assetsService } from '../../../services/AssetsService/AssetsService';
import { AssetsPipelineViewer } from './AssetsPipelineViewer';
import { getQueryString } from '../../../utils/getQueryString';
import { fcFromFeatures } from '../../../utils/geoJson.utils';
import { getAssetClassVisibilityMap } from '../../../utils/assets.utilities/getAssetClassVisibilityMap';
export const AssetsPipelineViewerContainer = ({ activeSessionId, activePipelineId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [allAssetsPipelinePayload, setAllAssetsPipelinePayload] = useState(null);
    const [assetsVisibilityFiltersByClassNameMap, setAssetsVisibilityFiltersByClassNameMap] = useState({});
    // Todo: add filter
    const assetsPipelinePayload = useMemo(() => {
        if (allAssetsPipelinePayload === null) {
            return null;
        }
        const newFeatures = allAssetsPipelinePayload
            .assetsFeatureCollection.features.filter((feature) => { var _a; return (_a = assetsVisibilityFiltersByClassNameMap[feature.properties.class_name]) === null || _a === void 0 ? void 0 : _a.isOn; });
        return {
            pipeline: allAssetsPipelinePayload.pipeline,
            assetsFeatureCollection: fcFromFeatures(newFeatures),
        };
    }, [allAssetsPipelinePayload, assetsVisibilityFiltersByClassNameMap]);
    const [assetsSessionsPayload, setAssetsSessionsPayload] = useState(null);
    const [reloadingTrigger, setReloadingTrigger] = useState({});
    const reloadAssetsData = () => setReloadingTrigger({});
    /*
     * Click handlers
     */
    const activateSession = (sessionId) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const currentParamsMap = Object.fromEntries(urlSearchParams.entries());
        if (sessionId === null) {
            delete currentParamsMap.activeSessionId;
        }
        else {
            currentParamsMap.activeSessionId = sessionId.toString();
        }
        const search = getQueryString(currentParamsMap, {});
        navigate({ ...location, search });
    };
    const activatePipeline = (pipeline) => {
        let newActivePipelineId = null;
        if (pipeline !== null) {
            if (pipeline.status === 'success') {
                newActivePipelineId = pipeline.uuid;
            }
            else {
                console.warn('Pipeline is still in progress');
            }
        }
        const urlSearchParams = new URLSearchParams(window.location.search);
        const currentParamsMap = Object.fromEntries(urlSearchParams.entries());
        if (newActivePipelineId === null) {
            if (currentParamsMap.activePipelineId) {
                delete currentParamsMap.activePipelineId;
            }
        }
        else {
            currentParamsMap.activePipelineId = newActivePipelineId;
        }
        const search = getQueryString(currentParamsMap, {});
        navigate({ ...location, search });
    };
    /*
     * Switching between pipelines and sessions
     * Existence of activePipelineId is a sign of pipeline mode on
     */
    useEffect(() => {
        (async () => {
            var _a, _b;
            setIsLoading(true);
            // If we have a pipeline ID but no session ID, load pipeline first to get session
            if (activePipelineId && !activeSessionId) {
                const pipelinePayload = await assetsService.getAssetsPipelinePayload(activePipelineId);
                const sessionId = ((_b = (_a = pipelinePayload === null || pipelinePayload === void 0 ? void 0 : pipelinePayload.pipeline) === null || _a === void 0 ? void 0 : _a.session_data) === null || _b === void 0 ? void 0 : _b.session_id) || null;
                activateSession(sessionId);
                return; // Exit early - the useEffect will run again with both IDs
            }
            // Normal flow - load both pipeline and session data
            const [activePipelinePayload] = await Promise.all([
                assetsService.getAssetsPipelinePayload(activePipelineId),
            ]);
            const [sessionsPayload] = await Promise.all([
                assetsService.getAssetsSessionsPayload(activeSessionId),
            ]);
            // Assign pipeline result
            setAllAssetsPipelinePayload(activePipelinePayload);
            // Assign visibility map
            const assetClassVisibilityMap = activePipelinePayload
                ? getAssetClassVisibilityMap(activePipelinePayload === null || activePipelinePayload === void 0 ? void 0 : activePipelinePayload.assetsFeatureCollection)
                : {};
            setAssetsVisibilityFiltersByClassNameMap(assetClassVisibilityMap);
            // Assign session results
            // setActiveSession(sessionsPayload.activeSession); //
            setAssetsSessionsPayload(sessionsPayload);
            setIsLoading(false);
        })();
    }, [activeSessionId, activePipelineId, reloadingTrigger]);
    /*
     * Render
     */
    const assetsPipelinePayloadWithMapProps = useMemo(() => {
        return assetsPipelinePayload
            ? {
                ...assetsPipelinePayload,
                assetsVisibilityFiltersByClassNameProps: {
                    assetsVisibilityFiltersByClassNameMap,
                    onSetAssetVisibilityFilterByClassNameMap: setAssetsVisibilityFiltersByClassNameMap,
                },
            } : null;
    }, [assetsVisibilityFiltersByClassNameMap, assetsPipelinePayload]);
    return (_jsx(AssetsPipelineViewer, { isLoading,
        onReloadDataRequested: reloadAssetsData,
        assetsPipelinePayloadWithMapProps,
        assetsSessionsPayload,
        activateSession,
        activatePipeline }));
};
