export const PATH = {
    index: {
        path: '/',
    },
    sessionsList: {
        path: '/sessions',
    },
    sessionsMap: {
        path: '/sessions-map',
    },
    /**
     * @deprecated
     * This is old path, used for redirect (to pciRegistry) purpose only
     */
    miniPci: {
        path: '/mini-pci',
    },
    pciRegistry: {
        path: '/pci/registry',
    },
    mapPicker: {
        path: '/map-picker',
    },
    pipelinesList: {
        path: '/pipelines',
    },
    pipelinesListTS: {
        path: '/pipelines-ts',
    },
    pipelineItem: {
        path: 'pipeline/:uuid',
        makePath: (uuid) => PATH.pipelineItem.path.replace(':uuid', uuid),
    },
    runPipeline: {
        path: '/pipeline/new',
    },
    rerunPipeline: {
        path: '/pipeline/rerun/:pipeline_uuid',
        makePath: (uuid) => PATH.rerunPipeline.path.replace(':pipeline_uuid', uuid),
    },
    createInvite: {
        path: '/create-invite',
    },
    acceptInvite: {
        path: '/accept-invite',
    },
    demoAutoLogin: {
        path: '/demo',
    },
    signIn: {
        path: '/signin',
    },
    signUp: {
        path: '/signup',
    },
    confirmCode: {
        path: '/confirm-code',
    },
    requestRestorePassword: {
        path: '/request-password-restore',
    },
    restorePassword2stage: {
        path: '/password-restore',
    },
    dataRequirements: {
        path: '/data-requirements',
    },
    userGuide: {
        path: '/user-guide',
    },
    assetsPipelineViewer: {
        path: '/assets-viewer',
    },
    aggregatedAssetsViewer: {
        path: '/aggregated-assets-viewer',
    },
    assetsRegistryQa: {
        path: '/assets-registry-qa',
    },
    profileSettingsPage: {
        path: '/settings/profile',
    },
    camLocPipelineViewer: {
        path: 'camloc/pipeline',
    },
    testPage: {
        path: '/test-page',
    },
    gaussianViewer: {
        path: '/gaussian-viewer',
    },
};
export const EXTERNAL_PATH = {
    roadLyHelpRoot: {
        path: 'https://help.road.ly',
    },
    roadlyProAppStore: {
        path: 'https://apps.apple.com/app/id6476778274',
    },
};
