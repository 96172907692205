import clsx from 'clsx'
import { ContentFor } from 'components/ContentFor/ContentFor'
import { SORT, ROLES, LOCAL_STORAGE_KEYS } from 'const'
import { SortableDateColumn } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/SortableDateColumn/SortableDateColumn'
import { useListSortMap } from '@roadar-pipeline-viewer/roadly-typescript/dist/hooks/useListSortMap'
import style from 'features/SessionsList/SessionsListContentHead/SessionsListContentHead.module.scss'
import { Sort } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/listingConstants'

const defaultSessionsSortMap = {
  created: Sort.DESC,
  updated: Sort.DESC,
}

export function SessionsListContentHead({ searchParams }) {
  let sortMap = useListSortMap(searchParams, LOCAL_STORAGE_KEYS.SESSIONS_LIST_SORT_MAP, defaultSessionsSortMap)
  
  if (sortMap === undefined) {
    sortMap = {
      created: 'desc',
      updated: 'desc'
    }
  }

  return (
    <>
      <div className={style.listHeaderGridContainer}>
        <ContentFor role={ROLES.ADMIN}>
          <div className={clsx(style.headCol, style.actionsCell)}>Actions</div>
        </ContentFor>
        <div className={clsx(style.headCol, style.sessionCell)}>Session Folder</div>
        <div className={clsx(style.headCol, style.statusCell)}>Session ID</div>
        <div className={clsx(style.headCol, style.emailCell)}>Email</div>
        <SortableDateColumn
          url=""
          className={style.headCol}
          lastSortOrder={sortMap.created}
          searchParams={searchParams}
          fieldName="created"
          label="Created"
        />
        <div className={clsx(style.headCol, style.distanceCell)}>Distance</div>
      </div>
    </>
  )
}
