import { useMemo } from 'react';
import { filterByStepAndIndex } from '../../utils/array.utilities/filterByStepAndIndex/filterByStepAndIndex';
/**
 * Returns a filtered frame segment sequence based on the current frame segment id and the navigation step size.
 * If the current frame segment id is not found in the sequence, it will return an empty array.
 * If the current frame segment id is found in the sequence, it will return the filtered sequence.
 *
 * @param frameSegmentIdsSequence - The sequence of frame segment ids.
 * @param currentFrameSegmentId - The current frame segment id.
 * @param navigationStepSize - The step size for navigation.
 * @param raiseErrorIfNotFound - Whether to raise an error if the current frame segment id is not found in the sequence.
 * @returns The filtered frame segment sequence.
 */
export const useFilteredFrameSegmentSequence = (frameSegmentIdsSequence, currentFrameSegmentId, navigationStepSize, raiseErrorIfNotFound) => {
    return useMemo(() => {
        if (!currentFrameSegmentId) {
            return frameSegmentIdsSequence;
        }
        return filterByStepAndIndex({
            array: frameSegmentIdsSequence,
            element: currentFrameSegmentId,
            stepSize: navigationStepSize,
            raiseErrorIfNotFound,
        });
    }, [frameSegmentIdsSequence, currentFrameSegmentId, navigationStepSize, raiseErrorIfNotFound]);
};
