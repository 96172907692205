import { wktToGeoJSON } from '@terraformer/wkt';
import { wktTypes } from '../../../const/geometry.constants';
function isGeometry(input) {
    return true;
}
function geoJsonToPolygonArray(geoJson, result) {
    if (!isGeometry(geoJson)) {
        throw new Error('WKT is not geometry');
    }
    if (geoJson.type === wktTypes.LineString) {
        result.push(geoJson.coordinates);
        return null;
    }
    if (geoJson.type === wktTypes.Polygon || geoJson.type === wktTypes.MultiLineString) {
        result.push(...geoJson.coordinates);
        return null;
    }
    if (geoJson.type === wktTypes.MultiPolygon) {
        geoJson.coordinates.forEach((el) => result.push(...el));
        return null;
    }
    if (geoJson.type === wktTypes.GeometryCollection) {
        geoJson.geometries.forEach((geometry) => geoJsonToPolygonArray(geometry, result));
        return null;
    }
    console.error('Unexpected geoJson type', geoJson);
    throw new Error('Unexpected geoJson type');
}
export function polygonArrayToSvgPath(polygonArray) {
    // svg path structure: M x y L x y L Z M x y L x y L x y Z
    // M - start of polygon Z - end of polygon
    return 'M ' + polygonArray.map(subPolygon => subPolygon.map(([x, y]) => `${x * 100} ${y * 100}`).join(' L ')).join('Z M') + ' Z';
}
export function wktStringToSVGPath(wktString) {
    const geoJson = wktToGeoJSON(wktString);
    const result = [];
    geoJsonToPolygonArray(geoJson, result);
    return polygonArrayToSvgPath(result);
}
