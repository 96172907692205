import { useState, useContext } from 'react';
import { RoadLyMapContext } from '../../../contexts/roadLyMapContext';
import { useProfile } from '../../../hooks/useProfile';
import { LoadingStatus } from '../../../const/appConstants';
import { API } from '../../../services/ApiService';
import { PciExportDataRange } from '../../../types/pci.types';
import { toast } from 'react-toastify';
export const useExportPciWithOptions = () => {
    const { profile } = useProfile();
    const [roadLyMapContext] = useContext(RoadLyMapContext);
    const [exportLoadingStatus, setExportLoadingStatus] = useState(LoadingStatus.INITIAL);
    const onClickExport = async (dataRange, perFrameSegment, format) => {
        setExportLoadingStatus(LoadingStatus.LOADING);
        try {
            if (dataRange === PciExportDataRange.all) {
                await API.userExportPciData(profile.organization_id, perFrameSegment, format);
            }
            else {
                await API.viewportExportPciData([[roadLyMapContext.boundingBox]], profile.organization_id, perFrameSegment, format);
            }
            setExportLoadingStatus(LoadingStatus.SUCCESS);
        }
        catch (error) {
            if (error instanceof Error) {
                toast.error(error.message);
            }
            else {
                toast.error('Export failed');
            }
            setExportLoadingStatus(LoadingStatus.ERROR);
        }
    };
    return {
        exportLoadingStatus,
        onClickExport,
    };
};
