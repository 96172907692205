import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button } from '@blueprintjs/core';
import { PciTableViewModal } from './PciTableView.modal';
export const PciTableViewButton = () => {
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (_jsxs(_Fragment, { children: [showModal
                ? _jsx(PciTableViewModal, { onCloseModal: handleCloseModal })
                : null, _jsx(Button, { minimal: true, onClick: handleShowModal, icon: "th", children: "Table view" })] }));
};
