import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonGroup, Button } from '@blueprintjs/core';
import { Link, useNavigate } from 'react-router-dom';
import { PATH } from '../../../const/routingConstants';
import { useEffect, useState } from 'react';
import { Progress } from '../../../const/appConstants';
import { PipelineListFilters } from '../../../const/pipelineConstants';
import utils, { getQueryString } from '../../../utils/utils';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { PipelineListItem } from '../PipelineListItem/PipelineListItem';
import style from './PipelineListContent.module.css';
import { PipelineBatchPreloader } from '../../../utils/PipelineListPreloader/PipelineBatchPreloader';
const { DEFAULT_SESSION } = PipelineListFilters;
const PipelineListEmpty = ({ status }) => {
    return (_jsxs("div", { className: style.empty, children: ["We don't have any pipelines with status:\u00A0", _jsx("b", { children: status })] }));
};
const PipelineListWithSessionEmpty = ({ session }) => {
    const navigate = useNavigate();
    return (_jsxs("div", { className: style.empty, children: ["We don't have any pipelines with name:\u00A0", _jsx("b", { children: session.folder_name }), _jsx(Button, { intent: "success", className: style.emptyButton, outlined: true, text: `Run pipeline with session ${session.folder_name}`, onClick: () => navigate({
                    pathname: PATH.runPipeline.path,
                    search: `?session=${session.id}`,
                }) })] }));
};
const PipelineListError = () => {
    return _jsx("div", { className: style.empty, children: "Something went wrong. Please try again later." });
};
const PaginationHref = ({ label, disabled, skip, searchParams }) => {
    const query = getQueryString(searchParams, { skip });
    if (disabled) {
        return _jsx(Button, { disabled: disabled, children: label });
    }
    return (_jsx(Link, { role: "button", tabIndex: 0, to: { search: query }, className: "bp4-button", children: label }));
};
const PipelineListPagination = ({ limit, skip, total, searchParams }) => {
    const hasNextPage = skip + limit < total;
    const hasPreviousPage = skip > 0;
    return (_jsx("div", { className: style.pagination, children: _jsxs(ButtonGroup, { children: [_jsx(PaginationHref, { label: "Prev page", disabled: !hasPreviousPage, skip: hasPreviousPage ? skip - limit : 0, searchParams: searchParams }), _jsx(PaginationHref, { label: "Next page", disabled: !hasNextPage, skip: hasNextPage ? skip + limit : skip, searchParams: searchParams })] }) }));
};
export const PipelineListContent = ({ progress, items, limit, skip, status, session, total, updatePipelineList, selectedSession, searchParams, updateItemPart, }) => {
    if (progress === Progress.WORK) {
        return _jsx(ProgressBar, {});
    }
    if (items.length === 0 && session !== DEFAULT_SESSION && selectedSession) {
        return _jsx(PipelineListWithSessionEmpty, { session: selectedSession });
    }
    if (items.length === 0 && progress === Progress.SUCCESS) {
        return _jsx(PipelineListEmpty, { status: status });
    }
    if (items.length === 0 && progress === Progress.ERROR) {
        return _jsx(PipelineListError, {});
    }
    const [preloadedPipelines, setPreloadedPipelines] = useState([]);
    const [preloadedOutputs, setPreloadedOutputs] = useState([]);
    // load full information about pipelines
    useEffect(() => {
        (async () => {
            const pipelineBatchPreloader = new PipelineBatchPreloader({ pipelineIdsSequence: [] });
            pipelineBatchPreloader.pipelineIdsSequence = items.map((item) => item.uuid);
            const newPipelinesPreloadResult = await pipelineBatchPreloader.preloadAll();
            setPreloadedPipelines(newPipelinesPreloadResult);
        })();
    }, [items]);
    // processing pipelines to outputs
    useEffect(() => {
        if (preloadedPipelines.length > 0) {
            const newOutputsResult = preloadedPipelines.map((pipeline) => utils.getAllOutput(pipeline.value));
            setPreloadedOutputs(newOutputsResult);
        }
    }, [preloadedPipelines]);
    return (_jsxs(_Fragment, { children: [items.map((item, index) => (_jsx(PipelineListItem, { initialItem: item, updatePipelineList: updatePipelineList, updateItemPart: updateItemPart, initialFullItem: preloadedPipelines.length > 0 ? preloadedPipelines[index].value : null, initialOutputs: preloadedOutputs.length > 0 ? preloadedOutputs[index] : [] }, item.uuid))), _jsx(PipelineListPagination, { limit: limit, skip: skip, total: total, searchParams: searchParams })] }));
};
