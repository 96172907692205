import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@blueprintjs/core';
import { DownloadIcon, ShareIcon } from '../../../../components/icons';
import { PciViewerDistressPolygonBB } from '../PciViewerDistressPolygonBB';
import styles from './PciViewerViewport.module.scss';
import { FrameSegmentDistressReprKey, } from '../../../../types/api/FrameSegmentResponseBody.types';
import { download } from '../../../../utils/download';
import { LoadingOverlay } from '../../../../components';
import { toast } from 'react-toastify';
export const PciViewerViewport = ({ viewpoint, imageViewType, frameSegmentImagesPreloadingResult, distresses, isFrameSegmentLoading, pciFSControlsProps, }) => {
    if (!frameSegmentImagesPreloadingResult) {
        return (_jsx("div", { className: styles.viewportContainer, children: _jsx("div", { className: styles.errorMessage, children: "Image data is missing" }) }));
    }
    const { frameSegmentImageBase64Structure, frameSegmentImageUrlStructure, } = frameSegmentImagesPreloadingResult;
    const imageUrl = frameSegmentImageBase64Structure.img;
    const roiUrl = frameSegmentImageBase64Structure.roi;
    const handleDownload = () => {
        const imageName = viewpoint.properties.id + '.jpg';
        download(imageUrl, imageName);
    };
    const handleShare = () => {
        const frameSegmentId = viewpoint.properties.id;
        const baseUrl = window.location.origin;
        const shareLink = `${baseUrl}/?frameSegmentId=${frameSegmentId}`;
        navigator.clipboard.writeText(shareLink).then(() => {
            toast.success('Link copied to clipboard');
        }).catch((err) => {
            console.error('Failed to copy link: ', err);
            toast.error('Failed to copy link');
        });
    };
    const isAnythingLoading = false;
    const showDistresses = !isAnythingLoading && pciFSControlsProps.isDistressVisible;
    return (_jsxs("div", { className: styles.viewportContainer, children: [_jsxs("div", { className: styles.iconButtonsContainer, children: [_jsx(Button, { small: true, onClick: handleDownload, className: styles.downloadButton, children: _jsx(DownloadIcon, {}) }), _jsx(Button, { small: true, onClick: handleShare, children: _jsx(ShareIcon, {}) })] }), isAnythingLoading && _jsx(LoadingOverlay, {}), _jsx("img", { src: imageUrl, alt: "Frame", crossOrigin: "anonymous", className: styles.viewportImage }), pciFSControlsProps.isRoiMaskVisible && (_jsx("img", { src: roiUrl, alt: "Roi", className: styles.viewportRoiImage })), showDistresses
                ? (_jsx(PciViewerDistressPolygonBB, { commonPci: viewpoint.properties.stats.pci, imageViewType: imageViewType, activeReprKey: FrameSegmentDistressReprKey.poly, distressesArray: distresses }))
                : null] }));
};
